
import React,{useContext,useState,useEffect} from 'react';

import { MyContext } from '../contexts/MyContext';

//include page
import Loginpage from './Loginpage';
import Forgetpassword from './Forgetpassword';
import Registerpage from './Registerpage';

import {Link } from "react-router-dom"; 

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ContentLoader from "react-content-loader";
let BaseURLuploads = 'https://giobooks.com/uploads/';

function Loginsection() {
  const {impressivelatestbook} = useContext(MyContext);//const {} = useContext(MyContext);
  const [isimpresivelatest, setimpresivelatest] = useState([]);
  

  useEffect( () => {
    //storedata(); 
    impressivelatest();
    window.scrollTo(0, 0);
  }, []);


  const impressivelatest = async() =>{
    try {
           const impressiveresult = await impressivelatestbook();
                  if(impressiveresult.success === 1){
                         console.log(impressiveresult);
                         setimpresivelatest(impressiveresult.bookslatestimpressive) ;
           }
    } catch (error) { throw error;}
}

  
  // const storedata = () => {
  //     let userstorageid = JSON.parse(localStorage.getItem('tockenbookid'));
  //     if (localStorage.getItem('tockenbookid')) {
  //         //this.setState({ bookuserid: userstorageid })} else { this.setState({ bookuserid: '', })    
  //     }
  // }

   const [isLogin, setisLogin] = useState(false);
   const [isRegister, setisRegister] = useState(false);
   const [isForget, setisForget] = useState(false);

   const RegisterMode = () => {
       window.scrollTo({top: 0, behavior: 'smooth'});
       setisLogin(true);
       setisRegister(true);
       setisForget(false);
      }
    
  const LoginMode = () => {
       window.scrollTo({top: 0, behavior: 'smooth'});
       setisLogin(false);
       setisRegister(false);
       setisForget(false);
    
  
  }
  const ForgetMode = () => {
       window.scrollTo({top: 0, behavior: 'smooth'});
       setisLogin(true);
       setisRegister(false);
       setisForget(true);
  }  



      // if (this.state.bookuserid) {
      //   return(
      //     <>
      //        {toast("please logout to access this page", {
      //          position: "top-center" })}
      //        <Redirect to='/9d6adc5f7a6f7a9311bf8cfebad6f84f/' />
      //     </>

      //   ) 
      // }
       
       
        return(
            <>
            {/* <ToastContainer /> */}
           
               <div className="login-1">
                  <div className="container">
                     <div className="row">
                   
                     
                       <div className="col-md-8 logsss3">
                      

                          {/* Login Mode */}
                          {isLogin !== true ?
                              <>
                              <Loginpage />
                                <h6 onClick={ForgetMode}> Forgot Password </h6>
                                <h6 onClick={RegisterMode}> Don't have an account? <span style={{color:'#fa6342'}}> Register </span>  </h6>
                             </>
                            : null }

                            {/* Register Mode */}
                              {isRegister === true ?
                                <>
                                <Registerpage LoginMode ={LoginMode}/>
                                  <h6 onClick={LoginMode}> Already have an Account ? <span style={{color:'#fa6342'}}> Login here </span></h6>
                                  </>
                              : null }

                              {/* Forget Password */}
                              {isForget === true ? 
                              <>
                              <Forgetpassword  />  
                              <h6 onClick={RegisterMode}> Don't have an account? <span style={{color:'#fa6342'}}> Register </span>  </h6>
                              </>
                              : null  }



                       </div>
                       <div className="col-md-4 lll">
                       {isimpresivelatest.length > 0 ? <>
                   
                     {isimpresivelatest.map((item, index) => ( 
                              <div key={item.books_id} className="col-md-12">
                              <div className="aaa4">
                <Link key={item.books_id} to={`/Bookpreview/${item.books_id}`}>
                <h1> <span className="cols"> Impressive </span> Books </h1>
                   <section className="app" id="app" data-current-media="book">
                   <div className="heading  price align-center color-white subcolor-main icon-type-hidden ltx-shop-slider-price heading-tag-h2" id="like_sc_header_892432379"><h2 className="header ">$27.36</h2></div>
                     
                               <div className="book-wrapper2">
                                     <img src={BaseURLuploads + item.imagefront} alt="books" />
                               </div>
                               
                              <h4> {item.title} </h4>
                               <div className="desc23sss" >
                                   <div dangerouslySetInnerHTML={{ __html: item.description }} />
                            </div>  
                             </section>    
       
               
               <div className="arrow-read-more">
                                   <FontAwesomeIcon icon={['fas','arrow-right']} className="iconsss2" />
                                         
                                          </div>
               <div className="arrow-read-more-2 secondary-font">Read More</div>
                                   </Link>
                </div> 
                </div> 
                            )) }
                          
                            </>
                            : <ContentLoader height={300} width={500} speed={2} primarycolor="#f3f3f3" secondarycolor="#ecebeb" > 
                           <rect x="37" y="27" rx="0" ry="0" width="227" height="300"/> 
                          
                             </ContentLoader>  }
                         </div>
                     

                     </div>
                  </div>
            </div>
            </>
        );
    }
export default Loginsection;