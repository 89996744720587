import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {toast} from "react-toastify";
import Bookedit from './Bookedit';
import Bookeditplan from './Bookeditplan';

import StripeCheckout from "react-stripe-checkout";

import { useHistory } from "react-router-dom";

let baseURLpaypal= 'https://giobooks.com/';

function Bookupgradepay(props) {

       let history = useHistory();
    const {newbookaddpreview,rootState,bookstripeupgrade} = useContext(MyContext);
    const {theUser} = rootState;
    const [isbookpreview, setisbookpreview] = useState([]);
    const [addClass, setaddClass] = useState();
    const [isEditing, setisEditing] = useState(false);
    const [isEditingplan, setisEditingplan] = useState(false);
    const [ispaymentprice, setpaymentprice] = useState(false);
    const [ispaymentbookid, setispaymentbookid] = useState(false);
    const [paynowpaypal, setpaynowpaypal] = useState(false);
    const [paynowstripe, setpaynowstripe] = useState(true);
    const [ispublish, setispublish] = useState(false);

    useEffect( () => {
       booklistpreview(props.match.params.Addpreview);
      }, []); 
   
      const publish = () => {
       setispublish(true);
      }
      const booklistpreview = async (ids) => {
       window.scrollTo(0, 0);
       try {
             
                     const dataaddpreview = await newbookaddpreview(ids);
                     if(dataaddpreview.success === 1){
                            console.log(dataaddpreview);
                            setisbookpreview(dataaddpreview.bookpreviewsall);
                            setpaymentprice(dataaddpreview.bookpreviewsall[0].upgradeplanamount)
                            setispaymentbookid(dataaddpreview.bookpreviewsall[0].books_id)
                     }
              } catch (error) { throw error;}    
       }
      
       const editModepreviewchildedit =() =>{
              setisEditing(false);
              booklistpreview(props.match.params.Addpreview);
       }
       const editModepreviewchildeditplan =() =>{
              setisEditingplan(false);
              booklistpreview(props.match.params.Addpreview);
       }
   
            
       let boxClass = ["redmoesss"];
       if(addClass) {
              boxClass.push('redmoesssqqq');
       
       }



   const makePayment = async (token) => {
          console.log(ispaymentbookid);
          console.log(ispaymentprice);
       try {
              const datastripe = await bookstripeupgrade(token,theUser,ispaymentbookid,ispaymentprice);
              console.log(datastripe);
              if(datastripe.success === 1){
                     history.push(`/StripeBookupgradeSuccess/${datastripe.stripeid}`);

                  } 
       } catch (error) { throw error;}    
   }


   
   const paypalMode = () => {
       setpaynowpaypal(true);
       setpaynowstripe(false);
   }
  const stripeMode = () => {
       setpaynowpaypal(false);
       setpaynowstripe(true);
   }

        return(
       <div className="bookadspreview">
              <div className="container">
                     <div className="row">
                     {isbookpreview.map((item, index) => ( 
                             <div key={item.books_id} className="col-md-12" style={{padding:0,}}> 
                             {theUser === item.user_id ? <>  
                     {isEditing === true && isEditingplan === false ?
                             <Bookedit booksid={props.match.params.Addpreview} title={item.title} image={item.imagefront} description={item.description} price={item.price} websitelink={item.websitelink} purchaselink={item.purchaselink} category={item.category} other={item.other} editModepreviewchilds={editModepreviewchildedit } />

                             : <>
                            {isEditingplan === true && isEditing === false ?
                             <Bookeditplan booksid={props.match.params.Addpreview} editModepreviewchildsplan={editModepreviewchildeditplan }/> : <>
                     
                             

                            <div className="col-md-3 yyy">
                                   &nbsp;
                          </div>
                          <div className="col-md-6 bookadspreviewlsss">
                          <h1> Onetime Fee </h1>
                          
                     <hr/>
                            <h3>Payment Plan : <span>{item.upgradeplan} </span></h3>
                            <h3>Total Amount : <span>${item.upgradeplanamount}</span> </h3>




                            {item.paymentbook === 'Free'  ?
                            <>
                             {ispublish === true  ?
                            <div className="ppp2"><p>Your book is pending manual approval by an admin. For instant and automatic approval, post a featured  book Ad</p></div> : <p onClick={publish} className="btn btn-primary2"> Publish  </p> }
                             
                             </>
                            : <> 

                          <div className="clearfix masssqqq"></div>
                            <h1>Choose your payment method</h1>
                            <hr/>
                          <div className="row-payment-method payment-row">
                     <div className="select-icon">
                            <input type="radio" id="radio2" name="radios" value="pp" defaultChecked onClick={stripeMode} />
                            <label htmlFor="radio2"></label>
                     </div>
                     <div className="select-txt">
                  
                            <p className="pymt-type-name"> <span className="stripsss"> Stripe </span>Credit / Debit Card </p>
                            <p className="pymt-type-desc">Safe payment online. Accept all major debit and credit cards from customers in every country.</p>
                     </div>
                    
                     
                     </div>

                     <hr />
                     <div className="row-payment-method payment-row">
                     <div className="select-icon">
                            <input type="radio" id="radio1" name="radios" value="pp"  onClick={paypalMode}/>
                            <label htmlFor="radio1"></label>
                     </div>
                     <div className="select-txt">
                    
                            <p className="pymt-type-name"><span className="Pay">Pay</span><span className="pal">pal</span></p>
                            <p className="pymt-type-desc">Safe payment online. Credit card needed. PayPal account is not necessary.</p>
                     </div>
                    
                     
                     </div>
                     <hr/>
 




                          <div className="clearfix"></div>
              {paynowstripe === true ? 
                          <StripeCheckout stripeKey="pk_live_yn6RjQtCI6a8ITcRqjeSdLKv00FSgvPKl8" token={makePayment} name={item.upgradeplan} description=" Onetime Fee" amount={item.upgradeplanamount * 100}  currency="USD" shippingAddress billingAddress >
                     <div className="thm-btn pricing-one__btn"> Pay Now <FontAwesomeIcon icon={['fas','shopping-cart']} className="iconsss2" /> </div>
                     </StripeCheckout>
              : 

                     <a href={baseURLpaypal+`paypalupgrade/pay/`+theUser+`/`+item.books_id+`/`+item.upgradeplanamount}  className="thm-btn pricing-one__btn" > Pay Now <FontAwesomeIcon icon={['fas','shopping-cart']} className="iconsss2" /></a>


              }

</>}
                     
                            </div>
                             
                            
                      </>
                     }
                     </>
                     }


</>: null}
                      </div>
                      ))}
             </div>
             </div>
             </div>
        );
    }

export default Bookupgradepay;