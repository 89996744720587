
import React,{useContext,useState} from 'react';
import { MyContext } from '../contexts/MyContext';

import Loader from 'react-loader-spinner';
import {toast} from "react-toastify";
import { useHistory } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Loginpage() {
    let history = useHistory();

    const {loginUser,isLoggedIn} = useContext(MyContext);
    
    const [loadinglogin, setloadinglogin] = useState(false);
    const [userInfo, setuserInfo] = useState({
        email: '',
        password: '',
    });
    const onChangeValue = (e) => {
        setuserInfo({
            ...userInfo,
                [e.target.name]:e.target.value
        });
    } 
    const userLogin = async (event) => {
        setloadinglogin(true);
        event.preventDefault();
        event.persist();
        const data = await loginUser(userInfo);
        if(data.success === 1){
            setloadinglogin(false);
            localStorage.setItem('loginToken',JSON.stringify(data.users[0].register_id));       
           toast.success('Welcome ' + data.users[0].name, {
            position: "top-right",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
          });  
          event.target.reset(); 
          await isLoggedIn();
            history.push('/Myaccount');
          
        }else{
            setloadinglogin(false);
            toast.error( data.msg, {
                position: "top-right",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
              });  
        }
        
    }
   
        return(
              
           <form onSubmit={userLogin} className="login-forms">
               
               <div className="breadcrumbs">
                            <div className="content-box">
                                <h1> Login into your account </h1>
                            </div>
                      </div>

                <p style={{marginBottom:"37px"}}> Welcome Back! Please sign in to your Account</p>
                 
                <div className="clearfix"></div>
                <div className="form-row">
                    <div className="form-group col-md-12">
                        <label className="font-weight-bold"> Email Address </label>
                        <input type="email" name="email" value={userInfo.email} onChange={onChangeValue}  className="form-control" placeholder="Email Address" required/>
                    </div>
                    <div className="form-group col-md-12">
                        <label className="font-weight-bold">Password</label>
                        <input type="password" name="password" value={userInfo.password} onChange={onChangeValue} className="form-control" placeholder="Password" required/>
                    </div>
                    <div className="form-group col-md-12 ">
                    {loadinglogin ?
                            <>
                                   <Loader type="ThreeDots" color="#f64442" height={67} width={67} style={{float: "left"}}/>
                            </>
                         : 
                        <button type="submit" className="thm-btn pricing-one__btn"> Log in <FontAwesomeIcon icon={['fas','arrow-circle-right']} /></button>
                    } 
                    </div>
                </div>
               
                
            </form>  
                  
        );
}
export default Loginpage;