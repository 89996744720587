import React, { Component } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Link } from "react-router-dom"; 
import ContentLoader from "react-content-loader";
import Pagination from 'react-bootstrap/Pagination'

import Moment from 'react-moment';
let BaseURL = 'https://giobooks.com/';
let BaseURLuploads = 'https://giobooks.com/uploads/';
class Publishers extends Component {
  
  state = {
    classifieds: [],
    total: null,
    per_page: null,
    current_page: 1,
  }

  

  componentDidMount() {
    this.makeHttpRequestWithPage(1);
   // window.scrollTo(0, 0);
  }


  makeHttpRequestWithPage = async pageNumber => {
    
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
    const response = await fetch(BaseURL+`viewall/Publishersss/${pageNumber}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const res = await response.json();
    console.log(res);
    this.setState({
       classifieds: res.data,
       total: res.total,
       per_page: res.per_page,
       current_page: res.page,
    });
  }

  render() {
    const loaderplatinum = [1,2,3,4,5,6,7,8];
    let  allUsers,mainData, renderPageNumbers;
    if (this.state.classifieds !== null) {
      allUsers = this.state.classifieds.map(ads => (
              <div className="col-md-4 ttt6" key={ads.publisher_id}>
                 <div className="aaa3">
              <Link to={`/PublisherPreview/${ads.publisher_id}`}>
                     <div className="booksrec2">
                     <img src={BaseURLuploads + ads.imagefront} alt="books" />
                     </div>
                     <div className="single-btn2">
                     <div className="desc" >
                                   <h2>{ads.title}</h2>
                                   <div dangerouslySetInnerHTML={{ __html: ads.description }} />
                            </div>       
                     </div>  </Link>
                     <div className="about-links">
                     <Link to={`/PublisherPreview/${ads.publisher_id}`} className="base-btn1"> View More </Link>
			</div> 

                        


                     
            
              </div>
              </div>  
       
      ));
      if( this.state.classifieds.length > 0){
        mainData = ( <div>{allUsers}  </div> );
    } else{
      mainData = ( <div>
                      {loaderplatinum.map((index) => (
                        <div key={ index } className="col-md-6" >
                        <ContentLoader height={337} width={500}speed={2}primarycolor="#f3f3f3" secondarycolor="#ecebeb" > 
                          <rect x="10" y="10" rx="0" ry="0" width="183" height="277"/> 
                          <rect x="227" y="17" rx="3" ry="3" width="260" height="11"/> 
                          <rect x="227" y="47" rx="3" ry="3" width="260" height="20"/> 
                          <rect x="227" y="90" rx="3" ry="3" width="47" height="7"/> 
                          <rect x="287" y="90" rx="3" ry="3" width="190" height="7"/> 
                          <rect x="227" y="107" rx="3" ry="3" width="87" height="7"/> 
                          <rect x="327" y="107" rx="3" ry="3" width="147" height="7"/> 
                          <rect x="227" y="127" rx="3" ry="3" width="177" height="7"/> 
                          
                          <rect x="227" y="177" rx="3" ry="3" width="147" height="7"/> 
                          <rect x="387" y="177" rx="3" ry="3" width="77" height="7"/> 
                          <rect x="227" y="197" rx="3" ry="3" width="87" height="7"/> 
                          <rect x="327" y="197" rx="3" ry="3" width="137" height="7"/> 
                          <rect x="227" y="217" rx="3" ry="3" width="167" height="7"/> 
                          
                          <rect x="227" y="247" rx="3" ry="3" width="97" height="17"/> 

                        </ContentLoader> 
                        </div>
                      ))}
                    </div> )
    }
    }else{
      return (
        <div className="" style={{padding:'60px'}}>
        <div className="container">
           <div className="row">
           <div className="col-md-12">
        <div className="alert alert-light" role="alert">
        <h4 className="alert-heading"> Please Wait </h4>
        <hr/>
        {loaderplatinum.map((index) => (
                        <div key={ index } className="col-md-6" >
                        <ContentLoader height={337} width={500}speed={2}primarycolor="#f3f3f3" secondarycolor="#ecebeb" > 
                          <rect x="10" y="10" rx="0" ry="0" width="183" height="277"/> 
                          <rect x="227" y="17" rx="3" ry="3" width="260" height="11"/> 
                          <rect x="227" y="47" rx="3" ry="3" width="260" height="20"/> 
                          <rect x="227" y="90" rx="3" ry="3" width="47" height="7"/> 
                          <rect x="287" y="90" rx="3" ry="3" width="190" height="7"/> 
                          <rect x="227" y="107" rx="3" ry="3" width="87" height="7"/> 
                          <rect x="327" y="107" rx="3" ry="3" width="147" height="7"/> 
                          <rect x="227" y="127" rx="3" ry="3" width="177" height="7"/> 
                          
                          <rect x="227" y="177" rx="3" ry="3" width="147" height="7"/> 
                          <rect x="387" y="177" rx="3" ry="3" width="77" height="7"/> 
                          <rect x="227" y="197" rx="3" ry="3" width="87" height="7"/> 
                          <rect x="327" y="197" rx="3" ry="3" width="137" height="7"/> 
                          <rect x="227" y="217" rx="3" ry="3" width="167" height="7"/> 
                          
                          <rect x="227" y="247" rx="3" ry="3" width="97" height="17"/> 

                        </ContentLoader> 
                        </div>
                      ))}
    </div>
    </div>
    </div>
    </div>
    </div>
      )
     }
    
    const pageNumbers = [];
    if (this.state.total !== null) {
      for (let i = 1; i <= Math.ceil(this.state.total / this.state.per_page); i++) {
        pageNumbers.push(i);
      }


      renderPageNumbers = pageNumbers.map(number => {
        //console.log('current_page: '+this.state.page+' number : '+number);
        let classes = this.state.current_page != number ? '' : 'active';

        return (
             
               <Pagination.Item key={number} className={classes}  onClick={() => this.makeHttpRequestWithPage(number)}>
               {number}
             </Pagination.Item>
       //    <span key={number} className={classes} onClick={() => this.makeHttpRequestWithPage(number)}> {number}</span>
        );
      });
    }



    return (
      <>
      
       <div className="ccc pagebooks">
     
       <div className="container">
          <div className="row">
          <div className="col-md-12">
          <div className="pagebooks2">
            <h2> Publishers </h2>
       
          {mainData}
          <div className="col-md-12">
                 
      <div className="pagination">
        <span onClick={() => this.makeHttpRequestWithPage(1)}><Pagination.First /></span>
        {renderPageNumbers}
        <span onClick={() => this.makeHttpRequestWithPage(this.state.total)}><Pagination.Last /></span>
      </div>
      </div>
      </div>
      </div>

   
    </div>
    </div>
    </div>
    </>
  );
}

}

export default Publishers
