
import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';

import Loader from 'react-loader-spinner';
import {toast} from "react-toastify";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Profile(props) {

    const {loginUserupdate,viewuserprofile} = useContext(MyContext);
    
    const [loadinglogin, setloadinglogin] = useState(false);
    const [userInfo, setuserInfo] = useState({
        name: '',
        password: '',
        confirmpassword: '',
    });
    
    const [userlistname, setuserlistname] = useState({username:''});

    useEffect( () => {
       userdetails(props.storageid);
      }, []);


 const userdetails = async(storageid) =>{
        try {
               const viewuser = await viewuserprofile(storageid);
               if(viewuser.success === 1){
                      console.log(viewuser);
                      setuserlistname({
                             ...userlistname,
                             username:viewuser.memberlist[0].name
                      });
                      setuserInfo({ ...userInfo,
                            name:viewuser.memberlist[0].name,
                       });
               }
        } catch (error) { throw error;}      
 }
 
    const onChangeValue = (e) => {
        setuserInfo({
            ...userInfo,
                [e.target.name]:e.target.value
        });
    } 
    const userLoginupdate = async (event) => {
       try {
      
              event.preventDefault();
              event.persist();
        if ( userInfo.password !== userInfo.confirmpassword ) {
              toast.error( 'Password and Confirm Password does not match', {
                     position: "top-right",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
                   });  
             
              return;
          }


          setloadinglogin(true);

        const data = await loginUserupdate(userInfo,props.storageid);
        if(data.success === 1){
            setloadinglogin(false);
            toast.success(data.msg, {
              position: "top-right",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
            });  
            event.target.reset();
           
         
          
        }else{
            setloadinglogin(false);
            toast.error( data.msg, {
                position: "top-right",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
              });  
        }
} catch (error) { throw error;}    
    }
  
   
        return(
              
           <form onSubmit={userLoginupdate} className="login-forms">
                <h3 className="myaccount-content"> Profile Settings</h3>
                <div className="clearfix"></div>
                <div className="form-row">
                    <div className="form-group col-md-12">
                        <label className="font-weight-bold"> Name </label>
                        <input type="text" name="name" value={userInfo.name} onChange={onChangeValue}  className="form-control" placeholder="Name" />
                    </div>
                    <div className="form-group col-md-12">
                        <label className="font-weight-bold">New Password</label>
                        <input type="password" name="password" value={userInfo.password} onChange={onChangeValue} className="form-control" placeholder="New Password" />
                    </div> 
                     <div className="form-group col-md-12">
                        <label className="font-weight-bold">Confirm Password</label>
                        <input type="password" name="confirmpassword" value={userInfo.confirmpassword} onChange={onChangeValue} className="form-control" placeholder="Confirm Password" />
                    </div>
                    <div className="form-group col-md-12 ">
                    {loadinglogin ?
                            <>
                                   <Loader type="ThreeDots" color="#f64442" height={67} width={67} style={{float: "left"}}/>
                            </>
                         : 
                        <button type="submit" className="thm-btn pricing-one__btn"> Save </button>
                    } 
                    </div>
                </div>
               
                
            </form>  
                  
        );
}
export default Profile;