
import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';
import Loader from 'react-loader-spinner';

import {toast} from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Link } from "react-router-dom"; 

import ContentLoader from "react-content-loader";
let BaseURL = 'https://giobooks.com/uploads/';

function Pendingpublisher(props) {

       const {paypendingpublisher,rootState} = useContext(MyContext);
       const [ispendingbook, setispendingbook] = useState({books:[]});    
       const {theUser} = rootState;
    


       useEffect( () => {
                pendingbooks(props.match.params.bookviews);
            }, []);


       const pendingbooks = async(booksids) =>{
              try {
                     const booksall = await paypendingpublisher(booksids);
                     if(booksall.success === 1){
                            console.log(booksall);
                            setispendingbook({
                                   ...ispendingbook,
                                   books:booksall.bookslist
                            }); 
                            
                     }
              } catch (error) { throw error;}      
       }
   
            return ( 
                   <>
                   <div className="paymentpendings">
                   <div className="container">
                   <div className="row">
                   <div className="col-md-12" style={{padding:0}}>
              <div className="breadcrumbs">
                <div className="content-box">
                  <h1> Book Payment Pending </h1>
                </div>
              </div>
            </div>

            


       {ispendingbook.books.length > 0 ?<>
                 {ispendingbook.books.map((item, index) => ( 
                        
                     <div key={item.publisher_id} className="col-md-6" >
                            {theUser === item.user_id? <> 
                                  <div className="basccsss">
                                         <div className="col-md-4" style={{padding:0}}>
                                                 <img src={BaseURL+item.imagefront} alt="books" className="" />
                                          </div>
                                          <div className="col-md-7 pagsssdesccc">
                                                 <h3>{item.title}</h3>
                                         
                                          <Link to={`/02b48f3ed729d18dcdb4cfe75c2ece5a/${item.publisher_id}`}  className="btn btn-primary5">  continue payment processing... </Link> 
                                          </div>
                                          </div>


                            </>
                            : null}
                        
                        </div>

                 ))}
             
     






       </>
       : null}       


       
</div>
</div>
</div>
             </>
              );
          }
   
export default Pendingpublisher;
