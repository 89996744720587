
import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';

import Loader from 'react-loader-spinner';
import {Link } from "react-router-dom"; 

import { useHistory } from "react-router-dom";

let BaseURL = 'https://giobooks.com/';


function StripeBookupgradeSuccess(props) {

  let history = useHistory();

  const {stripesuccessbook} = useContext(MyContext);
  const [stripedetails, setstripedetails] = useState([]);
  useEffect( () => {
     window.scrollTo(0, 0);
     successdata(props.match.params.Addpreview);
 }, []);


 const successdata = async (paymentid) => {
  const data = await stripesuccessbook(paymentid);
  if(data.success === 1){
    setstripedetails(data.bookslist);
  }
}


    return (
      <>
      <div className="successs">
        <div className="container">
          <div className="row">
            <div className="col-md-12" style={{padding:0}}>
              <div className="breadcrumbs">
                <div className="content-box">
                  <h1> Payment Receipt </h1>
                </div>
              </div>
            </div>
      
         
        <div className="col-md-2">
                        &nbsp;
              </div>
              <div className="col-md-7 ooo2">
             <h2>Your Payment Process Checking.</h2>
          
             {stripedetails.map((item, index) => (
                    <div key={item.pay_id}>

                  {item.payment_status === 'succeeded' ? 
                        <> 
                          <h3> Order No : F45842628-{item.member_id} </h3>    
                          <h3> Stripe transaction id :  {item.txn_id} </h3>    
                          <h3> Total Amount: {item.payment_gross} </h3>   
                          <h3> Currency: {item.currency_code} </h3>  
                          <h3> Payment Status : {item.payment_status} </h3> 
                          <Link to={`/Bookpreview/${item.member_id}`}  className="thm-btn pricing-one__btn" > View Book </Link>
                        </>
                         : 
                       <> 
                          <h3> Order No :F45842628-{item.member_id} </h3>    
                          <h3> Total Amount: {item.payment_gross} </h3>   
                          <h3> Currency: {item.currency_code} </h3>  
                          <h3 style={{color: "#fd2e1f"}}> Payment Status : {item.payment_status} </h3> 
                          <Link to={`/Bookpreview/${item.member_id}`} className="thm-btn pricing-one__btn" > Payment Again </Link>
                       </>
                       }       
                           
                          
                              
                           </div>

))}
      

</div>

</div>
</div>
</div>
</>
);
}


export default StripeBookupgradeSuccess;
