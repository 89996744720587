import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';

function Logout() {

    const {logoutUser} = useContext(MyContext);
    
    useEffect( () => {
        userlogout();
       }, []); 

    const userlogout = async() =>{
        try {
              await logoutUser();

        } catch (error) { throw error;}      
    }



        return(
             null
        );
    }

export default Logout;