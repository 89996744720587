import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {toast} from "react-toastify";
import PublisherEdit from './PublisherEdit';

import { useHistory } from "react-router-dom";
import StripeCheckout from "react-stripe-checkout";

import ContentLoader from "react-content-loader";

let BaseURL = 'https://giobooks.com/uploads/';

let baseURLpaypal= 'https://giobooks.com/';

function AddPublisherpreview(props) {
       let history = useHistory();
    const {newpublisheraddpreview,rootState,Publisherbookstripe} = useContext(MyContext);
    const {theUser} = rootState;
    const [isbookpreview, setisbookpreview] = useState([]);
    const [addClass, setaddClass] = useState();
    const [isEditing, setisEditing] = useState(false);
    const [ispaymentprice, setpaymentprice] = useState(false);
    const [ispaymentbookid, setispaymentbookid] = useState(false);
    const [paynowpaypal, setpaynowpaypal] = useState(false);
    const [paynowstripe, setpaynowstripe] = useState(true);

    useEffect( () => {
       booklistpreview(props.match.params.Addpreview);
      }, []); 
   
      const booklistpreview = async (ids) => {
       window.scrollTo(0, 0);
       try {
             
                     const dataaddpreview = await newpublisheraddpreview(ids);
                     if(dataaddpreview.success === 1){
                            console.log(dataaddpreview);
                            setisbookpreview(dataaddpreview.bookpreviewsall);
                            setpaymentprice(dataaddpreview.bookpreviewsall[0].paymentamount)
                            setispaymentbookid(dataaddpreview.bookpreviewsall[0].publisher_id)
                     }
              } catch (error) { throw error;}    
       }
       const editModepreview =() =>{
              setisEditing(true);
       }
      
       const editModepreviewchildedit =() =>{
              setisEditing(false);
              setisbookpreview([]);
              booklistpreview(props.match.params.Addpreview);
       }
      
      const toggle = () => {
               setaddClass({addClass: !addClass});
            }
            
       let boxClass = ["redmoesss"];
       if(addClass) {
              boxClass.push('redmoesssqqq');
       
       }


       const externallinks = (url) => {
              if (!!url && !!url.trim()) { 
                     url = url.trim(); 
                     if (!/^(https?:)?\/\//i.test(url)) { 
                         url = 'http://' + url; 
                         window.open(url, '_blank');
                     }else{
                            window.open(url, '_blank');
                     }
                 } 
            }  

   const makePayment = async (token) => {
          //console.log(ispaymentprice);
       try {
              const datastripe = await Publisherbookstripe(token,theUser,ispaymentbookid,ispaymentprice);
              console.log(datastripe);
              if(datastripe.success === 1){

                     history.push(`/StripePublisherSuccess/${datastripe.stripeid}`);

                  } 
       } catch (error) { throw error;}    
   }


   
   const paypalMode = () => {
       setpaynowpaypal(true);
       setpaynowstripe(false);
   }
  const stripeMode = () => {
       setpaynowpaypal(false);
       setpaynowstripe(true);
   }

        return(
       <div className="bookadspreview">
              <div className="container">
                     <div className="row">
               {isbookpreview.length > 0 ? <>        
                     {isbookpreview.map((item, index) => ( 
                             <div key={item.publisher_id} className="col-md-12" style={{padding:0,}}> 
                             {theUser === item.user_id ? <>  
                     {isEditing === true  ?
                             <PublisherEdit publisher_id={props.match.params.Addpreview} title={item.title} email={item.email} phone={item.phone} description={item.description} websitelink={item.websitelink}  editModepreviewchilds={editModepreviewchildedit } />

                             : <>
                            
                               
                             

                            <div className="col-md-8 yyy">

                            <button className="thm-btn pricing-one__btn editsss" onClick={editModepreview}> Edit <FontAwesomeIcon icon={['fas','edit']} className="iconsss2" /></button>
                            

                          
                     <div className="clearfix"></div>

                     <h1>{item.title}</h1>
                            <div className="col-md-12 zzz8">
                                  
                          <img src={BaseURL+item.imagefront}  alt="" />
                          </div>
                          <div className="col-md-12" style={{backgroundColor: '#ffffffb3'}}>
                         <div className="zzz7">
                       
                       
                          <h3> <FontAwesomeIcon icon={['fas','envelope']} className="iconsss2" /> Email : <span style={{color:"#ff4242"}} > {item.email}</span></h3>
                          <hr />
                           <h3> <FontAwesomeIcon icon={['fas','phone-alt']} className="iconsss2" />  Phone  : <span style={{color:"#ff4242"}} > {item.phone }</span></h3>
                          <hr />
                         
                          <h3> <FontAwesomeIcon icon={['fas','globe-americas']} className="iconsss2" />  Website Link  : <p onClick={() => externallinks(item.websitelink)}>{item.websitelink} </p> </h3>

                          </div>
                         

                          <div  className={boxClass.join(' ')}>
		                  {/* {descriptionviews} */}
                          <div dangerouslySetInnerHTML={{ __html: item.description }} />
                            </div>

                            {addClass ? 
                             <p className="redmoesssmmm" onClick={toggle}>  </p>
                             :
                             <p className="redmoesssmmm" onClick={toggle}> More </p>
                             }

</div>





                          </div>
                          <div className="col-md-4 bookadspreviewlsss">
                          <h1> Onetime Fee </h1>
                          
                     <hr/>
                            <h3> Publisher </h3>
                            <h3>Total Amount : <span>${item.paymentamount}</span> </h3>




                            {item.paymentbook === 'Free'  ?
                            <button> Submit </button>
                            : <> 

                          <div className="clearfix masssqqq"></div>
                            <h1>Choose your payment method</h1>
                            <hr/>
                          <div className="row-payment-method payment-row">
                     <div className="select-icon">
                            <input type="radio" id="radio2" name="radios" value="pp" defaultChecked onClick={stripeMode} />
                            <label htmlFor="radio2"></label>
                     </div>
                     <div className="select-txt">
                  
                            <p className="pymt-type-name"> <span className="stripsss"> Stripe </span>Credit / Debit Card </p>
                            <p className="pymt-type-desc">Safe payment online. Accept all major debit and credit cards from customers in every country.</p>
                     </div>
                    
                     
                     </div>

                     <hr />
                     <div className="row-payment-method payment-row">
                     <div className="select-icon">
                            <input type="radio" id="radio1" name="radios" value="pp"  onClick={paypalMode}/>
                            <label htmlFor="radio1"></label>
                     </div>
                     <div className="select-txt">
                    
                            <p className="pymt-type-name"><span className="Pay">Pay</span><span className="pal">pal</span></p>
                            <p className="pymt-type-desc">Safe payment online. Credit card needed. PayPal account is not necessary.</p>
                     </div>
                    
                     
                     </div>
                     <hr/>
 




                          <div className="clearfix"></div>
              {paynowstripe === true ? 
                          <StripeCheckout stripeKey="pk_live_yn6RjQtCI6a8ITcRqjeSdLKv00FSgvPKl8" token={makePayment} name="Publisher" description=" Onetime Fee" amount={item.paymentamount * 100}  currency="USD" shippingAddress billingAddress >
                     <div className="thm-btn pricing-one__btn"> Pay Now <FontAwesomeIcon icon={['fas','shopping-cart']} className="iconsss2" /> </div>
                     </StripeCheckout>
              : 

                     <a href={baseURLpaypal+`paypalPublisher/pay/`+theUser+`/`+item.publisher_id+`/`+item.paymentamount}  className="thm-btn pricing-one__btn" > Pay Now <FontAwesomeIcon icon={['fas','shopping-cart']} className="iconsss2" /></a>


              }

</>}
                     
                            </div>
                             
                            
                      </>
                     }
                    


</>: null}
                      </div>
                      ))}
                       </>
                      : 
                      <>     <div className="col-md-8 yyy">
                      <ContentLoader height={1700} width={1000} speed={3} primarycolor="#f3f3f3"  secondarycolor="#ecebeb" > 
                     <rect x="0" y="0" rx="0" ry="0" width="176" height="57"/> 
                     <rect x="517" y="0" rx="0" ry="0" width="276" height="57"/> 
                     <rect x="0" y="107" rx="0" ry="0" width="477" height="17"/> 
                     <rect x="0" y="147" rx="0" ry="0" width="800" height="477"/> 
                      
                      </ContentLoader>
             
                      </div> 
                      <div className="col-md-4 ">
                      <ContentLoader height={1700} width={1000} speed={3} primarycolor="#f3f3f3"  secondarycolor="#ecebeb" > 
                     <rect x="0" y="0" rx="0" ry="0" width="176" height="37"/> 
                     <rect x="" y="43" rx="0" ry="0" width="300" height="3"/> 
                     <rect x="" y="60" rx="0" ry="0" width="276" height="27"/> 
                     <rect x="" y="107" rx="0" ry="0" width="276" height="27"/> 

                     <rect x="0" y="177" rx="0" ry="0" width="437" height="33"/> 
                     <rect x="0" y="227" rx="0" ry="0" width="377" height="177"/> 
                     <rect x="0" y="427" rx="0" ry="0" width="377" height="177"/> 
                      
                      </ContentLoader>
                      </div> 
                      
                   </>
              }
             </div>
             </div>
             </div>
        );
    }

export default AddPublisherpreview;