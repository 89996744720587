
import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';

import {Link } from "react-router-dom"; 


function Admin(props) {

       const {viewuserprofile} = useContext(MyContext);
       const [loadinglogin, setloadinglogin] = useState(false);    

      
     
            return ( 
                   <>
             <h3 className="myaccount-content"> Admin </h3>
             
              <div className="col-md-12" style={{padding:0}}>
              <h3 className="myaccount-content"> Payment Pending </h3>
                     <div className="flag-wrapperadmin">
                     <Link to={`/Adminbookapproval`}> <span className="iii5">  Books </span></Link>
                     </div>
                     <div className="flag-wrapper">
                     <Link to={`/AdminAudioapproval`}> <span className="iii5">  Audio Books </span></Link>
                     </div>
                     <div className="flag-wrapper">
                     <Link to={`/Adminvideoapproval`}> <span className="iii5">  Books Trailers </span></Link>
                     </div> 
                     <div className="flag-wrapper">
                     <Link to={`/Adminpublisherapproval`}> <span className="iii5">  Publisher </span></Link>
                     </div>

                     <h3 className="myaccount-content"> Free books </h3>
                     <div className="flag-wrapperadmin">
                     <Link to={`/AdminFreeapproval`}><span className="iii5">  Free </span></Link>
                     </div>
                     <div className="clearfix"></div>
                     <h3 className="myaccount-content"> Change Price </h3>
                    <div className="flag-wrapperadmin">
                    <Link to={`/PriceUpdate`}>  <span className="iii5">  Price </span></Link>
                     </div>
              </div>  

         
             </>
              );
          }
   
export default Admin;
