import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import ContentLoader from "react-content-loader";
function Bookeditplan(props) {

       
    const {pricelistall,Addplanupdate,rootState} = useContext(MyContext);
    const [isprice, setisprice] = useState({Standard:'',Featured:'',Impressive:'',BestDeals:''});
    const {theUser} = rootState;
    const [packagePlan, setpackagePlan] = useState('Featured');

    useEffect( () => {
        pricelist();
       }, []); 

       const pricelist = async (event) => {
              const data = await pricelistall();
              if(data.success === 1){
                     console.log(data);
                     setisprice({ 
                            ...isprice,
                            Standard:data.priceall[3].price,
                            Featured:data.priceall[2].price,
                            Impressive:data.priceall[1].price,
                            BestDeals:data.priceall[0].price,
                     }) ;
              }
       }
       const booksAddplansupdate = async (event) => {
              window.scrollTo(0, 0);
              try {
                     event.preventDefault();
                     event.persist();
              if(theUser !== null){
                     const dataplanupdate = await Addplanupdate(props.booksid,packagePlan);
                     if(dataplanupdate.success === 1){
                            console.log(dataplanupdate);
                            event.target.reset();
                            props.editModepreviewchildsplan(); 
                           
                            
                     }
              }
              } catch (error) { throw error;}    
       }


const packageselected = (event) => {
       setpackagePlan(event.target.value)
     }


        return(
               <div className="addbook">
                     <div className="container">
                            <div className="row">
                                   <div className="col-md-12">
        <h2 className="xxx"> <span className="cols">Onetime Fee: </span> Paypal / Stripe / Credit Card Payment </h2>
                                   </div>

  {isprice.Standard !== '' ? 
       <form onSubmit={booksAddplansupdate}>



              <div className="col-md-2" style={{padding:0}}>
                     &nbsp;
              </div>
              <div className="col-md-7">
              
     
       <ul className="timeline">
       <button type="submit" className="thm-btn lsss"> Continue <FontAwesomeIcon icon={['fas','arrow-circle-right']} /></button>
              <li>
                     <div className="direction-lsss"> 
                            <div className="radioqqq">
                                   <input type="radio" className="radio" id="awesome-item-1"
                                   value="Standard"
                                   name="Standard"
                                   checked={packagePlan === "Standard"}
                                   onChange={packageselected}  />
                                   <label className="radio__label" htmlFor="awesome-item-1"> Active </label>
                            
                                   <div className="clearfix"></div>
                            </div>
                     </div>
                     <div className="direction-r">
                     <div className="flag-wrapper">
                            <span className="flag"> Standard - <div className="price">
                                   <span className="currency">$</span>
                                   <span className="value"> {isprice.Standard}</span>
                                   <span className="duration"> 1 Year</span>
                            </div></span>
                     </div>
                     <div className="radioqqq">
                            <div className="clearfix"></div>
                            <span className="listing-duration"> Visible one year  </span> <br/>
                            <span className="listing-duration">Listing</span>
                                          
                     </div>
                     </div>
              </li>
 <li>
                     <div className="direction-lsss"> 
                            <div className="radioqqq">
                                   <input type="radio" className="radio" id="awesome-item-2"
                                   value="Featured"
                                   name="Featured"
                                   checked={packagePlan === "Featured"}
                                   onChange={packageselected}  />
                                   <label className="radio__label" htmlFor="awesome-item-2"> Active </label>
                            
                                   <div className="clearfix"></div>
                            </div>
                     </div>
                     <div className="direction-r">
                     <div className="flag-wrapper">
                            <span className="flag"> Featured - <div className="price">
                                   <span className="currency">$</span>
                                   <span className="value">{isprice.Featured} </span>
                                   <span className="duration"> 1 Year</span>
                            </div></span>
                     </div>
                     <div className="radioqqq">
                            <div className="clearfix"></div>
                            <span className="listing-duration"> Visible 5 years  </span> <br/>
                            <span className="listing-duration">LinkedIn Group Recommendation, Facebook Page Posting, Tweets and Email Marketing</span>
                                          
                     </div>
                     </div>
              </li>
<li>
                     <div className="direction-lsss"> 
                            <div className="radioqqq">
                                   <input type="radio" className="radio" id="awesome-item-3"
                                   value="BestDeals"
                                   name="BestDeals"
                                   checked={packagePlan === "BestDeals"}
                                   onChange={packageselected}  />
                                   <label className="radio__label" htmlFor="awesome-item-3"> Active </label>
                            
                                   <div className="clearfix"></div>
                            </div>
                     </div>
                     <div className="direction-r">
                     <div className="flag-wrapper">
                            <span className="flag"> Best Deals - <div className="price">
                                   <span className="currency">$</span>
                                   <span className="value">{isprice.BestDeals} </span>
                                   <span className="duration"> 1 Year</span>
                            </div></span>
                     </div>
                     <div className="radioqqq">
                            <div className="clearfix"></div>
                            <span className="listing-duration"> Visible 10 years  </span> <br/>
                            <span className="listing-duration">Listing, LinkedIn Group Recommendation(200000+ Subscribers), Facebook Page Posting(Page size 120000+ Followers), Tweets and Email Marketing (Audience size 500000+ Subscribers)</span>
                                          
                     </div>
                     </div>
              </li>
<li>
                     <div className="direction-lsss"> 
                            <div className="radioqqq">
                                   <input type="radio" className="radio" id="awesome-item-4"
                                   value="Impressive"
                                   name="Impressive"
                                   checked={packagePlan === "Impressive"}
                                   onChange={packageselected}  />
                                   <label className="radio__label" htmlFor="awesome-item-4"> Active </label>
                            
                                   <div className="clearfix"></div>
                            </div>
                     </div>
                     <div className="direction-r">
                     <div className="flag-wrapper">
                            <span className="flag"> Impressive - <div className="price">
                                   <span className="currency">$</span>
                                   <span className="value">{isprice.Impressive} </span>
                                   <span className="duration"> 1 Year</span>
                            </div></span>
                     </div>
                     <div className="radioqqq">
                            <div className="clearfix"></div>
                           
                            <span className="listing-duration"> Permanent listing, search optimization,  Email marketing to over 140000 readers, Recommended to over 300000 LinkedIn members. Posting on Facebook page with 150000 followers.</span>
                                          
                     </div>
                     </div>
              </li>
<li  className="disllqqqsss">
                     <div className="direction-lsss"> 
                            <div className="radioqqq">
                                   <input type="radio" className="radio" id="awesome-item-5"
                                   value="Free"
                                   name="Free"
                                   checked={packagePlan === "Free"}
                                   onChange={packageselected}  />
                                   <label className="radio__label" htmlFor="awesome-item-5"> Active </label>
                            
                                   <div className="clearfix"></div>
                            </div>
                     </div>
                     <div className="direction-r">
                     <div className="flag-wrapper">
                            <span className="flag"> Free </span>
                     </div>
                     <div className="radioqqq">
                            <div className="clearfix"></div>
                            <span className="listing-duration"> Visible one year  </span> <br/>
                            <span className="listing-duration">Listing</span>
                                          
                     </div>
                     </div>
              </li> 
              <button type="submit" className="thm-btn rsss"> Continue <FontAwesomeIcon icon={['fas','arrow-circle-right']} /></button>
              </ul>

       
                     

                            
              </div>

              </form>
                :  <>
                <div className="col-md-7">
                <ContentLoader height={1700} width={1000} speed={3} primarycolor="#f3f3f3"  secondarycolor="#ecebeb" > 
               <rect x="127" y="0" rx="0" ry="0" width="176" height="57"/> 
               <rect x="207" y="77" rx="0" ry="0" width="7" height="1000"/>
  
               <rect x="200" y="147" rx="100" ry="100" width="20" height="20"/> 
               <rect x="60" y="137" rx="0" ry="0" width="122" height="50"/> 
               <rect x="247" y="137" rx="0" ry="0" width="220" height="55"/> 
               <rect x="247" y="207" rx="0" ry="0" width="477" height="17"/> 
                
               <rect x="200" y="327" rx="100" ry="100" width="20" height="20"/> 
               <rect x="60" y="317" rx="0" ry="0" width="122" height="50"/> 
               <rect x="247" y="317" rx="0" ry="0" width="220" height="55"/> 
               <rect x="247" y="390" rx="0" ry="0" width="477" height="17"/> 
  
               <rect x="200" y="517" rx="100" ry="100" width="20" height="20"/> 
               <rect x="60" y="507" rx="0" ry="0" width="122" height="50"/> 
               <rect x="247" y="507" rx="0" ry="0" width="220" height="55"/> 
               <rect x="247" y="580" rx="0" ry="0" width="537" height="17"/> 
               <rect x="247" y="607" rx="0" ry="0" width="137" height="17"/> 
  
               <rect x="200" y="717" rx="100" ry="100" width="20" height="20"/> 
               <rect x="60" y="707" rx="0" ry="0" width="122" height="50"/> 
               <rect x="247" y="707" rx="0" ry="0" width="220" height="55"/> 
               <rect x="247" y="777" rx="0" ry="0" width="537" height="17"/> 
               <rect x="247" y="807" rx="0" ry="0" width="537" height="17"/> 
               <rect x="247" y="837" rx="0" ry="0" width="137" height="17"/> 
              
               <rect x="200" y="937" rx="100" ry="100" width="20" height="20"/> 
               <rect x="60" y="927" rx="0" ry="0" width="122" height="50"/> 
               <rect x="247" y="927" rx="0" ry="0" width="220" height="55"/> 
  
               <rect x="127" y="1100" rx="0" ry="0" width="176" height="57"/> 
                </ContentLoader>
       
                </div>
                 </>
                   }

               </div>
       </div>
</div>
        );
    }

export default Bookeditplan;