
import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';
import {Link } from "react-router-dom"; 


function Pending(props) {

       const {paypendingbooks,paypendingaudio,paypendingvideo,paypendingpublisher} = useContext(MyContext);
       const [ispendingpaybook, setispendingpaybook] = useState(false);    
       const [ispendingpayaudio, setispendingpayaudio] = useState(false);    
       const [ispendingpayvideo, setispendingpayvideo] = useState(false);    
       const [ispendingpaypublisher, setispendingpaypublisher] = useState(false);    

    


       useEffect( () => {
              pendingbooks(props.storageid);
              pendingaudio(props.storageid);
              pendingvideo(props.storageid);
              pendingpublisher(props.storageid);
            }, []);


       const pendingbooks = async(storageid) =>{
              try {
                     const booksall = await paypendingbooks(storageid);
                     if(booksall.success === 1){
                            console.log(booksall);
                            setispendingpaybook(true); 
                     }
              } catch (error) { throw error;}      
       }
       const pendingaudio = async(storageid) =>{
              try {
                     const booksallaudio = await paypendingaudio(storageid);
                     if(booksallaudio.success === 1){
                            console.log(booksallaudio);
                            setispendingpayaudio(true); 
                     }
              } catch (error) { throw error;}      
       }
       const pendingvideo = async(storageid) =>{
              try {
                     const booksallvideo = await paypendingvideo(storageid);
                     if(booksallvideo.success === 1){
                            console.log(booksallvideo);
                            setispendingpayvideo(true); 
                     }
              } catch (error) { throw error;}      
       }
       const pendingpublisher = async(storageid) =>{
              try {
                     const booksallpublisher = await paypendingpublisher(storageid);
                     if(booksallpublisher.success === 1){
                            console.log(booksallpublisher);
                            setispendingpaypublisher(true); 
                     }
              } catch (error) { throw error;}      
       }

   
            return ( 
                   <>
             <h3 className="myaccount-content"> Payment Pending </h3>
             
                     <div className="col-md-5" style={{padding:0}}>
                            {ispendingpaybook=== true ? <>
                                   <div className="flag-wrapper">
                                   <Link to={`/BookPaymentPending/${props.storageid}` }><span className="iii4">  Book Payment Pending </span></Link>
                                   </div>
                            </>
                            : null}
                            {ispendingpayaudio=== true ? <>
                                   <div className="flag-wrapper">
                                   <Link to={`/AudioBookPaymentPending/${props.storageid}` }><span className="iii4">   Audio Book Payment Pending  </span></Link>
                                   </div>
                            </>
                            : null}
                            {ispendingpayvideo=== true ? <>
                                   <div className="flag-wrapper">
                                   <Link to={`/BookTrailerPaymentPending/${props.storageid}` }> <span className="iii4">  Book Trailer Payment Pending  </span></Link>
                                   </div>
                            </>
                            : null}
                            {ispendingpaypublisher=== true ? <>
                                   <div className="flag-wrapper">
                                   <Link to={`/PublisherPaymentPending/${props.storageid}` }><span className="iii4">   Publisher Payment Pending  </span></Link>
                                   </div>
                            </>
                            : null}
      
                     </div>
             </>
              );
          }
   
export default Pending;
