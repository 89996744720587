import React,{useContext,useState,useEffect} from 'react';

import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

import {Link } from "react-router-dom"; 
import { MyContext } from '../contexts/MyContext';

import ContentLoader from "react-content-loader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Moment from 'react-moment';
import userphotos from '../Img/user_profile.jpg';
import bee from '../Img/bee-floating-1.png';

let BaseURLuploads = 'https://giobooks.com/uploads/';

function Home() {
       const loaderplatinum = [1,2,3,4,5];
       const loaderlatest = [1,2,3,4];
       const loaderfeatured = [1,2,3,4,5,6,7,8];
       const loaderfeatured2 = [1,2,3,4,5,6];
       const loaderfeatured3 = [1,2];
       const {bestdeals,impressivelatestbook,latestallbookslist,impresivebooks,slidertrailers,sliderAudio,slidefeatured,sliderstandard,sliderPublisherqqq} = useContext(MyContext);
       const [isbestdeals, setbestdeals] = useState([]);
       const [isimpresivelatest, setimpresivelatest] = useState([]);
       const [isallbookslatest, setallbookslatest] = useState([]);
       const [isimpresive, setimpresive] = useState([]);

       const [databooktrailers, setDatabooktrailers] = useState([]);
       const [databookAudio, setDatabookAudio] = useState([]);
       const [databookFeatured, setDatabookFeatured] = useState([]);
       const [databookStandard, setDatabookStandard] = useState([]);
       const [databookPublisher, setDataPublisher] = useState([]);
      // const [isprofilephotos, setisprofilephotos] = useState([]);

       useEffect( () => {
              bestdealsnew();
              impressivelatest();
              latestallbooks();
              impresive();
              slidersbookstrailers();
              slidersbooksAudio();
              slidersFeatured();
              slidersStandard();
              sliderPublisher();
              //profilephotosimpressive();


       }, []);

const bestdealsnew = async() =>{
       try {
              const sliderresult = await bestdeals();
                     if(sliderresult.success === 1){
                            console.log(sliderresult);
                            setbestdeals(sliderresult.bookslistdeals) ;
              }
       } catch (error) { throw error;}
 }
 const impressivelatest = async() =>{
       try {
              const impressiveresult = await impressivelatestbook();
                     if(impressiveresult.success === 1){
                            console.log(impressiveresult);
                            setimpresivelatest(impressiveresult.bookslatestimpressive) ;
              }
       } catch (error) { throw error;}
 }
 const latestallbooks = async() =>{
       try {
              const latestallbookssss = await latestallbookslist();
                     if(latestallbookssss.success === 1){
                            console.log(latestallbookssss);
                            setallbookslatest(latestallbookssss.bookslistlatest) ;
              }
       } catch (error) { throw error;}
 } 
  const impresive = async() =>{
       try {
              const impresivebooksqqq = await impresivebooks();
                     if(impresivebooksqqq.success === 1){
                            console.log(impresivebooksqqq);
                            setimpresive(impresivebooksqqq.bookslatestimpresive) ;
              }
       } catch (error) { throw error;}
 } 
const slidersbookstrailers = async() =>{
       try {
              const trailersresult = await slidertrailers();
                     if(trailersresult.success === 1){
                            console.log(trailersresult);
                            setDatabooktrailers(trailersresult.bookstrailerslatest) ;
              }
       } catch (error) { throw error;}
}

const slidersFeatured = async() =>{
       try {
              const Featuredresult = await slidefeatured();
                     if(Featuredresult.success === 1){
                            console.log(Featuredresult);
                            setDatabookFeatured(Featuredresult.booksFeatured) ;
              }
       } catch (error) { throw error;}
}
const slidersStandard = async() =>{
       try {
              const Standardresult = await sliderstandard();
                     if(Standardresult.success === 1){
                            console.log(Standardresult);
                            setDatabookStandard(Standardresult.booksStandard) ;
              }
       } catch (error) { throw error;}
}
const slidersbooksAudio = async() =>{
       try {
              const Audioresult = await sliderAudio();
                     if(Audioresult.success === 1){
                            console.log(Audioresult);
                            setDatabookAudio(Audioresult.booksAudio) ;
              } 
       } catch (error) { throw error;}
}
 const sliderPublisher = async() =>{
       try {
              const sliderPublishersss = await sliderPublisherqqq();
                     if(sliderPublishersss.success === 1){
                            console.log(sliderPublishersss);
                            setDataPublisher(sliderPublishersss.booksPublisher) ;
              }
       } catch (error) { throw error;}
}
//  const profilephotosimpressive = async() =>{
//        try {
//               const profilephotosqqq = await profilephotos();
//                      if(profilephotosqqq.success === 1){
//                             console.log(profilephotosqqq);
//                             setisprofilephotos(profilephotosqqq.profilephotosall) ;
//               }
//        } catch (error) { throw error;}
// }
 


const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]{11,11}).*/;  

  return (
       <div className="col-md-12" style={{padding:0}}>
       
       <div className="topslider">
              <div className="col-md-4 aaa" style={{padding:0}}>
                     
 {isimpresivelatest.length > 0 ? <>
                     <Carousel slidesPerScroll={1} slidesPerPage={1} infinite 
                            breakpoints={{ 640: { slidesPerPage: 1, arrows: false}, 900: {slidesPerPage: 2, arrows: false  }  }}  >
                     {isimpresivelatest.map((item, index) => ( 
                             <div key={item.books_id} className="col-md-12" style={{padding:0,width:'100%'}}> 
                             <Link  to={`/Bookpreview/${item.books_id}`}  >
                                   
                                  
                                   <div className="col-md-3" style={{padding:0}}>
                                          <img src={BaseURLuploads + item.imagefront} alt="books" />
                                   </div>
                                   <div className="col-md-9" style={{padding:0}}>
                                          <h4 className="aaa2">{item.title}</h4>
                                   </div>
                            </Link>
                            </div>
                            )) }
                            </Carousel>
                            </>
                            : <ContentLoader height={97} width={500} speed={2} primarycolor="#f3f3f3" secondarycolor="#ecebeb" > 
                            <rect x="37" y="0" rx="0" ry="0" width="53" height="77"/> 
                            <rect x="117" y="10" rx="0" ry="0" width="500" height="22"/> 
                          
                             </ContentLoader>  }

              </div>
              <div className="col-md-4" style={{padding:0}}>
                      <h1> <span className="cols">Best  </span> Deals</h1>
              </div>
              <div className="col-md-4 aaa" style={{padding:0}}>
              {isallbookslatest.length > 0 ? <>
                     <Carousel slidesPerScroll={1} slidesPerPage={5} infinite 
                            breakpoints={{ 640: { slidesPerPage: 1, arrows: false}, 900: {slidesPerPage: 2, arrows: false  }  }}  >
                     {isallbookslatest.map((item, index) => ( 
                             <Link key={item.books_id} to={`/Bookpreview/${item.books_id}`}>
                                   <img src={BaseURLuploads + item.imagefront} alt="books" />
                            </Link>

                            )) }
                            </Carousel></>
                            : <>
                            {loaderlatest.map((item, index) => ( 
                                   <div key={ index } className="col-md-3" >
                                   <ContentLoader height={97} width={500} speed={2} primarycolor="#f3f3f3" secondarycolor="#ecebeb" > 
                            <rect x="7" y="0" rx="0" ry="0" width="53" height="77"/>  
                          
                             </ContentLoader>  
                             </div>
                            ))}
                            </>
                            }
              </div>






   <div className="clearfix"></div>
 {isbestdeals.length > 0 ? <>
<Carousel slidesPerScroll={1} slidesPerPage={5} infinite autoPlay={20000}
  animationSpeed={1000}
breakpoints={{
       640: {
         slidesPerPage: 1,
         arrows: false
       },
       900: {
         slidesPerPage: 3,
         arrows: false
       }
     }}
     >
 
         {isbestdeals.map((item, index) => ( 
         <Link key={item.books_id} to={`/Bookpreview/${item.books_id}`}>

            <section className="app" id="app" data-current-media="book">
            {item.price !== 0 ? 
                     <div className="heading  price align-center color-white subcolor-main icon-type-hidden ltx-shop-slider-price heading-tag-h2" id="like_sc_header_892432379"><h2 className="header ">${item.price}</h2></div>
            : null}
                <article className="media-container">
                        <div className="book-wrapper">
                          <div className="book">
                            <div className="book__front">
                              <img src={BaseURLuploads + item.imagefront} alt="books" />
                            </div>
                            <div className="book__paper"></div>
                            <div className="book__back"></div>
                          </div>
                          <div className="book-shadow"></div>
                        </div>
                      </article>
                      </section>    

         </Link>

          )) } 
         
 
</Carousel> 
</>
          : 
          <Carousel slidesPerScroll={1} slidesPerPage={5} infinite autoPlay={3000}
          animationSpeed={1000}>
          {loaderplatinum.map((item, index) => ( 
                <div key={ index } className="col-md-3" >
               <ContentLoader height={500} width={210}speed={2}primarycolor="#f3f3f3" secondarycolor="#ecebeb" > 
               <rect x="10" y="37" rx="0" ry="0" width="183" height="277"/> 
             
                </ContentLoader> 
       </div>
       ))}
      </Carousel>
  }


         </div>




  <div className="topslider4">
  <div className="container">
      <h1> <span className="cols"> Impressive </span> Books </h1>
      {isimpresive.length > 0 ? <>
       <Carousel slidesPerScroll={1} slidesPerPage={1} infinite  arrows infinite autoPlay={25000}
  animationSpeed={1000}
       breakpoints={{ 640: { slidesPerPage: 1, arrows: false}, 900: {slidesPerPage: 1, arrows: false  }  }}  >
         {isimpresive.map((item, index) => ( 
        <div  key={item.books_id} className="imsss">   
        <div className="col-md-6" style={{padding:0}}> 
        <span className="title-table">{item.category === 'other' ?
                            <>{item.other}</>
                            :  <>{item.category}  </>}</span>
              <div className="bosss"> 
              
                     <h2>{item.title}</h2>  
                     <h6 className="bann"> By: <span> {item.name} </span> </h6>
                     <div className="desc2" >
                            <div dangerouslySetInnerHTML={{ __html: item.description }} />
                     </div>  
                     <div className="about-links">
                     <Link to={`/Bookpreview/${item.books_id}`} className="base-btn1"> View Book </Link>
			</div>
              
              </div>  
         </div>  
        <div className="col-md-6" style={{padding:0}}>   
         <Link to={`/Bookpreview/${item.books_id}`}>

         <figure className="book2 mx-auto">
		<ul className="hardcover_front list-unstyled"><li>
			<div className="coverDesign">
                            <img src={BaseURLuploads + item.imagefront} alt="books"   className="img-fluid"/>
			</div>
		</li><li></li></ul>
		<ul className="page"><li></li><li>
			<span>
                     {item.profile_image !== '' ?
                            <>
                                   <img src={BaseURLuploads+item.profile_image} alt="author" className="img-fluid rounded-circle" />
                            </>
                     : 
                     
                            <>
                                   <img src={userphotos} alt="author" className="img-fluid rounded-circle"/>
                            </>
                            }
			     
			<span> A book by <br /> {item.name} </span></span>
		</li><li></li><li></li><li></li></ul>
		<ul className="hardcover_back list-unstyled"><li></li><li></li></ul>
		<ul className="book_spine list-unstyled"><li></li><li></li></ul>
	</figure> 
         </Link>
         </div>    
         </div>      
          )) }
</Carousel> </>: <>
<Carousel  >
       <div className="col-md-12" >
                     <ContentLoader height={500} width={1349} speed={2} primarycolor="#f3f3f3" secondarycolor="#ecebeb" > 
                     <rect x="0" y="0" rx="0" ry="0" width="627" height="503"/> 
                     <rect x="800" y="127" rx="0" ry="0" width="300" height="347"/> 
              
                     </ContentLoader> 
              </div>
          
              </Carousel>
              </> }

              </div>
         </div>
      
<section  className="topslider2">
<h1>  Book  <span className="cols">Trailers </span></h1>
       <div className="ltx-overlay-hex"></div>
       <div className="wpb_single_image wpb_content_element vc_align_right   ltx-bee-floating-right">
		

		<figure className="wpb_wrapper vc_figure">
			<div className="vc_single_image-wrapper   vc_box_border_grey"><img width="54" height="61" src={bee} className="vc_single_image-img attachment-full" alt="" /></div>
		</figure>
	</div>
 <div className="">
 {databooktrailers.length > 0 ? <>
       <Carousel slidesPerScroll={1} slidesPerPage={1}  centered  dots arrows  >

                     {databooktrailers.map((item, index) => ( 
                            <div key={item.video_id}>
                                 
                                          {item.url.match(regExp) ? 
                                          <> 
                                                 {item.url.match(regExp).length >= 2 ? 

                                                 <iframe id="ytplayer" type="text/html" className="videosss2"
                                                 src={`https://www.youtube.com/embed/` + item.url.match(regExp)[2] + `?rel=0&showinfo=0&color=white&iv_load_policy=3`}
                                                 frameBorder="0" allowFullScreen></iframe>
                                                 : null }
                                          </>
                                                 : null 
                                          } 
                            </div>
                     )) }
           
       </Carousel></>: <>
<Carousel  >
       <div className="col-md-12" >
                     <ContentLoader height={500} width={1349} speed={2} primarycolor="#f3f3f3" secondarycolor="#ecebeb" > 
                     <rect x="300" y="37" rx="0" ry="0" width="777" height="400"/> 
              
                     </ContentLoader> 
              </div>
          
              </Carousel>
              </> }


</div> 
</section>




<div className="topslider6">
      <h1> <span className="cols"> Featured  </span> Books </h1>
      <div className="col-md-12">
    
 {databookFeatured.length > 0 ? <>
       {databookFeatured.map((item, index) => ( 
               <div key={item.books_id} className="col-md-3">
                       <div className="aaa4">
         <Link key={item.books_id} to={`/Bookpreview/${item.books_id}`}>

            <section className="app" id="app" data-current-media="book">
            <div className="heading  price align-center color-white subcolor-main icon-type-hidden ltx-shop-slider-price heading-tag-h2" id="like_sc_header_892432379"><h2 className="header ">${item.price}</h2></div>
              
                        <div className="book-wrapper2">
                              <img src={BaseURLuploads + item.imagefront} alt="books" />
                        </div>
                        
                       <h4> {item.title} </h4>
                        <div className="desc23sss" >
                            <div dangerouslySetInnerHTML={{ __html: item.description }} />
                     </div>  
                      </section>    

        
				<div className="arrow-read-more">
                            <FontAwesomeIcon icon={['fas','arrow-right']} className="iconsss2" />
                                  
                                   </div>
				<div className="arrow-read-more-2 secondary-font">Read More</div>
                            </Link>
         </div> 
         </div> 
          )) } </> : <>
              {loaderfeatured.map((item, index) => ( 
                            <div key={ index } className="col-md-3" >
                            <ContentLoader height={337} width={300}speed={2}primarycolor="#f3f3f3" secondarycolor="#ecebeb" > 
                            <rect x="37" y="27" rx="0" ry="0" width="227" height="300"/> 
                     
                            </ContentLoader> 
                     </div>
                     ))} 
                     </>
              }

         </div>
         </div>

<div className="clearfix"></div>

<div className="topslider3">
       
<div className="container">
<div className="col-md-12" style={{padding:0}}>

    
      <h1> <span className="cols"> Audio </span> Books</h1>
      {databookAudio.length > 0 ? <>
<Carousel slidesPerScroll={1} slidesPerPage={4} infinite dots arrows
  animationSpeed={1000}
breakpoints={{
       640: {
         slidesPerPage: 1,
         arrows: false
       },
       900: {
         slidesPerPage: 2,
         arrows: false
       }
     }}
     >
 
         {databookAudio.map((item, index) => ( 
          <Link key={item.audio_id} to={`/AudioBookPreview/${item.audio_id}`}>

<div className="audsss">
         <div className="col-md-6" style={{padding:0}}>
              <div className="booksrec4">
                     <div className="item-wrap">
                            <div className="item-detail">
                                   <div className="item-img products-thumb"> 
                                          <img src={BaseURLuploads + item.imagefront} alt="books" />
                                   </div>
                                   <div className="product-header">
                        <span className="cccgggsss"> by : {item.written} </span>              
                        <h3 className="rectisss"> 
                        {item.title} 
                        
                        </h3>
                     
                      </div>
                            </div>
                     </div>
              </div>
                      </div>  
                     
                      </div>     

         </Link>

          )) } 
         
 
</Carousel> 
</>
          : 
          <Carousel slidesPerScroll={1} slidesPerPage={5} infinite autoPlay={3000}
          animationSpeed={1000}>
          {loaderplatinum.map((item, index) => ( 
                <div key={ index } className="col-md-3" >
               <ContentLoader height={500} width={210}speed={2}primarycolor="#f3f3f3" secondarycolor="#ecebeb" > 
               <rect x="10" y="37" rx="0" ry="0" width="183" height="277"/> 
             
                </ContentLoader> 
       </div>
       ))}
      </Carousel>
  }

     
 
         </div>


         </div>
         </div>


         <div className=" publsssqqq">
         <div className="col-md-12 ">
         <h1> <span className="cols">  Publishers  </span>  </h1>
         {databookPublisher.length > 0 ? <>
         {databookPublisher.map((item, index) => ( 
                <div key={item.publisher_id} className="col-md-3">
                <div className="aaa3 sfdfgsss">
              <Link  to={`/PublisherPreview/${item.publisher_id}`}>
             

                     <div className="booksrec2">
                     <img src={BaseURLuploads + item.imagefront} alt="books" />
                     </div>
                     <h6 className="">{ item.title} </h6>
                     <div className="desc23sss" >
                            <div dangerouslySetInnerHTML={{ __html: item.description }} />
                     </div>     
                     
              </Link>
              </div>
              </div>
          )) }
          </> : <>
          {loaderfeatured3.map((item, index) => ( 
                        <div key={ index } className="col-md-3" >
                        <ContentLoader height={300} width={300} speed={2} primarycolor="#f3f3f3" secondarycolor="#ecebeb" > 
                        <rect x="37" y="27" rx="0" ry="0" width="300" height="300"/> 
                 
                        </ContentLoader> 
                 </div>
                 ))} 
                 </>
          }
         </div>
         </div>


        


       <div className="clearfix"></div>

         <div className="topslider5">
      
         <h1> <span className="cols"> Standard  </span> Books </h1>

      <div className="col-md-12 standsss" style={{padding:0}}>
      {databookStandard.length > 0 ? <>
         {databookStandard.map((item, index) => (
                <div key={item.books_id} className="col-md-3 articles" > 
                <article className="standsss2">
                       <div className="post-thumb">
			<div className="date-published">
				<span className="big"><Moment format="DD MMM YYYY" withTitle>
                                          {item.date} 
                                     </Moment></span>
                           
			</div> 
                     <Link  to={`/Bookpreview/${item.books_id}`}>
                             <img src={BaseURLuploads + item.imagefront} className="cover-image lazyload visible" alt="books" />
                            
                     </Link>			
					
                     </div>
                     <h6>{item.category === 'other' ?
                                <>{item.other}</>
                              :  <>{item.category}  </>}</h6>
                     <h2 className="post-title">  <Link className="article-title" to={`/Bookpreview/${item.books_id}`}> {item.title} </Link></h2>

                     <Link className="article-title" to={`/Bookpreview/${item.books_id}`}>
				<div className="arrow-read-more">
                            <FontAwesomeIcon icon={['fas','arrow-right']} className="iconsss2" />
                                  
                                   </div>
				<div className="arrow-read-more-2 secondary-font">Read More</div>
                            </Link>


             
         </article>
         </div>
          )) }
          </> : <>
              {loaderfeatured.map((item, index) => ( 
                            <div key={ index } className="col-md-3" >
                            <ContentLoader height={337} width={300} speed={2} primarycolor="#f3f3f3" secondarycolor="#ecebeb" > 
                            <rect x="37" y="27" rx="0" ry="0" width="227" height="300"/> 
                     
                            </ContentLoader> 
                     </div>
                     ))} 
                     </>
              }
         </div>
        
         </div>

       {/* <div className="clearfix"></div>
       <div className="ttt8" > 
         {isprofilephotos.map((item, index) => (
                <div key={item.books_id} className="col-md-2 ttt8s" > 
          <Link  to={`/Bookpreview/${item.books_id}`}  >
                 <span>{item.name}</span>
                 
                     <img src={BaseURLuploads + item.profile_image} alt="books" />
                    
                     </Link>
         </div>
          )) }
   </div> */}




         
         </div>
         
  );
}

export default Home;