

import React,{useContext,useState,useEffect} from 'react';

import { MyContext } from '../contexts/MyContext';
import Loader from 'react-loader-spinner';
import {toast} from "react-toastify";

function Forgetpassword() {

    const {ForgetMailpassword} = useContext(MyContext);
    const [ForgotuserInfo, setForgotuserInfo] = useState({useremail: '', });
    
    const [loadingloginforget, setloadingloginforget] = useState(false);

    const onChangeValue = (e) => {
        setForgotuserInfo({
            ...ForgotuserInfo,
                [e.target.name]:e.target.value
        });
    } 

    const ForgetMail = async (event,) => {
        setloadingloginforget(true);
            event.preventDefault();
            event.persist();
            const dataForgetMail = await ForgetMailpassword(ForgotuserInfo);
            if(dataForgetMail.success === 1){
                setloadingloginforget(false);
                toast.success(dataForgetMail.msg,  {
                    position: "top-center",autoClose: 7000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
                  });
                  event.target.reset();

               
            }else{
                setloadingloginforget(false);
                toast.error(dataForgetMail.msg, {
                    position: "top-center",autoClose: 7000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
                  });
            }
            
        }
   



        return(
            <form onSubmit={ForgetMail} className="login-forms">
                <div className="breadcrumbs">
                            <div className="content-box">
                                <h1> Recover your password </h1>
                            </div>
                      </div>
                <div className="clearfix"></div>
            <div className="form-row">
           
              
                <div className="form-group col-md-12">
                    <label className="font-weight-bold">Email</label>
                    <input type="email" name="useremail" value={ForgotuserInfo.useremail} onChange={onChangeValue} className="form-control" placeholder="Email"/>
                </div>
                {loadingloginforget  ? 
            <>   <Loader type="ThreeDots" color="#ff7500" height={67} width={67} style={{float: "left"}}/> </>
            : 
                <div className="form-group col-sm-12 ">
                    <button type="submit" className="thm-btn pricing-one__btn" style={{
                      marginBottom: "37px"}}> Send Me Email </button>
                </div>
    }
            </div>
           
        </form>  
        );
    }

export default Forgetpassword;