import React,{useContext,useState,useEffect} from 'react';

import { MyContext } from '../contexts/MyContext';
import {Redirect,Link } from "react-router-dom"; 
import { toast} from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DashboardAccount from './DashboardAccount';
import Profiles from './Profile';
import Pendingall from './Pending';
import Adminall from './Admin';
import Logout from './Logout';



function Myaccount() {

  const {rootState,} = useContext(MyContext);
   const {isAuth,theUser} = rootState;
   const [isDashboard, setisDashboard] = useState(false);
   const [isProfile, setisProfile] = useState(false);
   const [isLogOut, setisLogOut] = useState(false);
   const [isPending, setisPending] = useState(false);
   const [isAdmin, setisAdmin] = useState(false);

  useEffect( () => {
    window.scrollTo(0, 0);
   
}, []);

 const Dashboard =()=>{
       setisDashboard(false);
       setisProfile(false);
       setisLogOut(false);
       setisAdmin(false);
       setisPending(false);
     }
 const Profile =()=>{
        setisDashboard(true);
        setisProfile(true);
        setisLogOut(false);
        setisAdmin(false);
        setisPending(false);
     }
const Pending =()=>{
        setisDashboard(true);
        setisPending(true);
        setisAdmin(false);
        setisProfile(false);
        setisLogOut(false);
     }
const Admin =()=>{
        setisDashboard(true);
        setisPending(false);
        setisAdmin(true);
        setisProfile(false);
        setisLogOut(false);
     }

 const LogOut =()=>{
        setisDashboard(true);
        setisProfile(false);
        setisAdmin(false);
        setisLogOut(true);
        setisPending(false);
     }
//      adminpayment =()=>{
//        this.setState({ 
//         isDashboard: true,
//         isClassified: false,
       
//         isPassword: false,
//         isMedia: false,
//         isLogOut: false,
//         isadminpayment: true,
//         isadmincoupen: false,
//        });
//      }
    

     

    return (
       <div className="members">
        <div className="container">
          <div className="row">
            <div className="col-md-12" style={{padding:0}}>
              <div className="breadcrumbs">
                <div className="content-box">
                  <h1> My Account </h1>
                </div>
              </div>
            </div>
              <div className="col-md-12" style={{padding:0}}>
              <ul className="memberstabsss">
                     <li onClick={Dashboard} className={isDashboard? "nav-item": "active" } >
                     <FontAwesomeIcon icon={['fas','th-large']} className="iconsss2" />
                     Dashboard 
                     </li>
                  
                    
                     <li onClick={Profile} className={ isProfile ? "active": "nav-item" }>
                     <FontAwesomeIcon icon={['fas','user-cog']} className="iconsss2" />
                     Profile Settings </li> 

                   <li onClick={Pending} className={ isPending ? "active": "nav-item" }>
                     <FontAwesomeIcon icon={['fas','money-check-alt']} className="iconsss2" />
                     Payment Pending </li> 
                     {theUser === 2 || theUser === 6 ?
                    <li onClick={Admin} className={ isAdmin ? "active": "nav-item" }>
                     <FontAwesomeIcon icon={['fas','money-check-alt']} className="iconsss2" />
                     Admin </li> 
                     : null }
                   
                 
                     
                     <li onClick={LogOut} className={isLogOut? "active": "nav-item" }>
                     <FontAwesomeIcon icon={['fas','sign-out-alt']} className="iconsss2" />
                     Log Out
                     </li>
              </ul>

              </div>
              <div className="col-md-1">
                &nbsp;
                </div>
              <div className="col-md-10 aaa6">
              {isAuth === true && theUser !== null ?
                     <div className="card card-body">
                    
                            {isDashboard !== true && theUser !=='' ?
                                   <>
                                     <DashboardAccount  storageid={theUser} />
                                   </>
                                   : null } 
                          
                          
                            {isProfile === true ?
                                   <>
                                   <Profiles storageid={theUser}  />
                                   </>
                                   : null }
                            {isPending === true ?
                                   <>
                                   <Pendingall storageid={theUser}  />
                                   </>
                                   : null }
                             {isAdmin === true ?
                                   <>
                                   <Adminall storageid={theUser}  />
                                   </>
                                   : null }
                            
                      
                                   {isLogOut === true ?
                                          <Logout /> 
                                   : null } 

                     </div>
                     : null }
              </div>

       </div>
      </div>
      



       </div>
    );
  }
export default Myaccount;