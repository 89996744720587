import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {toast} from "react-toastify";
import VideoEdit from './VideoEdit';
import { useHistory } from "react-router-dom";



function BookTrailersPreview(props) {
       let history = useHistory();
    const {newvideoaddpreview,rootState,videobookstripe} = useContext(MyContext);
    const {theUser} = rootState;
    const [isbookpreview, setisbookpreview] = useState([]);
    const [addClass, setaddClass] = useState();
    const [isEditing, setisEditing] = useState(false);
    const [ispaymentprice, setpaymentprice] = useState(false);
    const [ispaymentbookid, setispaymentbookid] = useState(false);

    useEffect( () => {
       booklistpreview(props.match.params.Addpreview);
      }, []); 
   
      const booklistpreview = async (ids) => {
       window.scrollTo(0, 0);
       try {
             
              const dataaddpreview = await newvideoaddpreview(ids);
              if(dataaddpreview.success === 1){
                     console.log(dataaddpreview);
                     setisbookpreview(dataaddpreview.bookpreviewsall);
                     setpaymentprice(dataaddpreview.bookpreviewsall[0].paymentamount)
                     setispaymentbookid(dataaddpreview.bookpreviewsall[0].video_id)
                     }
              } catch (error) { throw error;}    
       }
       const editModepreview =() =>{
              setisEditing(true);
       }
      
       const editModepreviewchildedit =() =>{
              setisEditing(false);
              booklistpreview(props.match.params.Addpreview);
       }
      
     



   const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]{11,11}).*/;

   
        return(
       <div className="bookadspreview">
              <div className="container">
                     <div className="row">
                     {isbookpreview.map((item, index) => ( 
                             <div key={item.video_id} className="col-md-12" style={{padding:0,}}> 
                            
                     {isEditing === true  ?
                             <VideoEdit video_id={props.match.params.Addpreview} title={item.title} url={item.url} editModepreviewchilds={editModepreviewchildedit } />

                             : <>
                            
                               
                             

                            <div className="col-md-8 yyy">
                            {theUser === item.user_id || theUser === 2  || theUser === 6 ? <>  

                            <button className="thm-btn pricing-one__btn editsss" onClick={editModepreview}> Edit <FontAwesomeIcon icon={['fas','edit']} className="iconsss2" /></button>
                            

                          
                     <div className="clearfix"></div>

                     <h1>{item.title}</h1>
                            <div className="col-md-12 zzz8">

       {item.url.match(regExp) ? <> 
            {item.url.match(regExp).length >= 2 ? <>      
                            <iframe id="ytplayer" type="text/html" className="videosss"
                         src={`https://www.youtube.com/embed/`+item.url.match(regExp)[2]+`?rel=0&showinfo=0&color=white&iv_load_policy=3`}
                         frameBorder="0" allowFullScreen></iframe> 

                          </>:null}
                          </>:null}
                          
                          </div>




                          </>: null}
                          </div>
                          
                            
                      </>
                     }
                    



                      </div>
                      ))}
             </div>
             </div>
             </div>
        );
    }

export default BookTrailersPreview;