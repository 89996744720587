import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from 'react-loader-spinner';
import { useHistory } from "react-router-dom";

import {toast} from "react-toastify";

function PriceUpdate() {

  let history = useHistory();

       
       const {rootState,pricelistall,changeprice} = useContext(MyContext);
       const {theUser} = rootState;
    
       const [loadingadd, setloadingadd] = useState(false);
       const [userInfo, setuserInfo] = useState({
              BestDeals: '',
              Impressive: '',
              Featured: '',
              Standard: '',
              Audio: '',
              Publisher: '',
              BookTrailer: '',
                
       });

    
       
       useEffect( () => {
              pricelist();
              window.scrollTo(0, 0);
       }, []); 
     
       const pricelist = async () => {
              const data = await pricelistall();
              if(data.success === 1){
                     console.log(data);
                     setuserInfo({ 
                            ...userInfo,
                            BestDeals:data.priceall[0].price,
                            Impressive:data.priceall[1].price,
                            Featured:data.priceall[2].price,
                            Standard:data.priceall[3].price,
                            Audio:data.priceall[4].price,
                            Publisher:data.priceall[5].price,
                            BookTrailer:data.priceall[6].price,
                     }) ;
              }
       }
      

     
       const onChangeValue = (e) => {
              setuserInfo({
                  ...userInfo,
                      [e.target.name]:e.target.value
              });
          } 

       const updateprice = async (event) => {
              try {
                     setloadingadd(true);
                            event.preventDefault();
                            event.persist();
                            const dataadd = await changeprice(userInfo);
                            
                            console.log(dataadd);
                            if(dataadd.success === 1){
                                   setloadingadd(false);
                                   toast.success( 'Success', {
                                          position: "top-right",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
                                        }); 
                                        history.push('/PostYourBook'); 
                                 
                                 
                            }else{
                                   setloadingadd(false);
                                    toast.error( 'Please Try Again', {
                                      position: "top-right",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
                                    });  
                            }

              } catch (error) { throw error;}    
       }
   
        return(
               <div className="bookads2">
                     <div className="container">
                            <div className="row">
                                   <div className="col-md-2">
                                     &nbsp;
                                     </div>
                                   <div className="col-md-8">

                                   <h3 className="yyy2"> Price  </h3>
                {theUser === 2 || theUser === 6 ?       
              <form onSubmit={updateprice} className="central-meta">
              <span className="adverts-form-required2"> <b> * Denotes A Required Field </b></span>
              <div className="clearfix"></div>
              <div className="form-row">
           
                  <div className="form-group col-md-12">
                      <label className="font-weight-bold"> Best Deals <span className="adverts-form-required"> * </span> </label>
                      <input type="text" name="BestDeals" value={userInfo.BestDeals} onChange={onChangeValue}  className="form-control" placeholder="Best Deals" required />
                  </div> 
                  <div className="form-group col-md-12">
                      <label className="font-weight-bold"> Impressive Books <span className="adverts-form-required"> * </span> </label>
                      <input type="text" name="Impressive" value={userInfo.Impressive} onChange={onChangeValue}  className="form-control" placeholder="Impressive Books" required />
                  </div> 
                 <div className="form-group col-md-12">
                      <label className="font-weight-bold"> Featured Books <span className="adverts-form-required"> * </span> </label>
                      <input type="text" name="Featured" value={userInfo.Featured} onChange={onChangeValue}  className="form-control" placeholder="Featured Books" required />
                  </div> 
                   <div className="form-group col-md-12">
                      <label className="font-weight-bold"> Standard Books <span className="adverts-form-required"> * </span> </label>
                      <input type="text" name="Standard" value={userInfo.Standard} onChange={onChangeValue}  className="form-control" placeholder="Standard Books" required />
                  </div> 
                 <div className="form-group col-md-12">
                      <label className="font-weight-bold"> Audio Books <span className="adverts-form-required"> * </span> </label>
                      <input type="text" name="Audio" value={userInfo.Audio} onChange={onChangeValue}  className="form-control" placeholder="Audio Books" required />
                  </div> 
                 <div className="form-group col-md-12">
                      <label className="font-weight-bold">Publisher <span className="adverts-form-required"> * </span> </label>
                      <input type="text" name="Publisher" value={userInfo.Publisher} onChange={onChangeValue}  className="form-control" placeholder="Publisher" required />
                  </div> 
                 <div className="form-group col-md-12">
                      <label className="font-weight-bold">Book Trailers <span className="adverts-form-required"> * </span> </label>
                      <input type="text" name="BookTrailer" value={userInfo.BookTrailer} onChange={onChangeValue}  className="form-control" placeholder="Book Trailers" required />
                  </div> 
                 
        
               
         
                  {loadingadd ?
                            <>
                                   <Loader type="ThreeDots" color="#f64442" height={67} width={67} style={{float: "left"}}/>
                            </>
                         : 
              <div className="form-group col-sm-12 text-right">
                      <button type="submit" className="btn btn-primary"> Continue  <FontAwesomeIcon icon={['fas','angle-double-right']} /></button>
                  </div>
                   }
              
             
              </div>
              
          </form>
           : null }            
          </div>
          </div>
          </div>
          </div>
        );
    }

export default PriceUpdate;