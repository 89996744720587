import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {toast} from "react-toastify";
import AudioBookedit from './AudioBookedit';
import HelmetMetaData from "./HelmetMetaData";
import {Link } from "react-router-dom"; 
import userphotos from '../Img/user_profile.jpg';
import {EmailShareButton,FacebookShareButton,LinkedinShareButton,PinterestShareButton,TwitterShareButton,WhatsappShareButton} from "react-share";
import ContentLoader from "react-content-loader";
let BaseURLShare = 'https://giobooks.com/';
let BaseURL = 'https://giobooks.com/uploads/';

let BaseURLuploads = 'https://giobooks.com/uploads/';


function AudioBookPreview(props) {

    const {Audiopreview,rootState,impressivelatestbook} = useContext(MyContext);
    const {theUser} = rootState;
    const [isbookpreview, setisbookpreview] = useState([]);
    const [addClass, setaddClass] = useState();
    const [isEditing, setisEditing] = useState(false);
    const [isEditingplan, setisEditingplan] = useState(false);
    const [isimpresivelatest, setimpresivelatest] = useState([]);

    const loaderplatinum = [1,2,3,4,5,6,7,8];
    
    useEffect( () => {
       booklistpreview(props.match.params.Addpreview);
       
    impressivelatest();
      }, []); 
      const impressivelatest = async() =>{
       try {
              const impressiveresult = await impressivelatestbook();
                     if(impressiveresult.success === 1){
                            console.log(impressiveresult);
                            setimpresivelatest(impressiveresult.bookslatestimpressive) ;
              }
       } catch (error) { throw error;}
   }
     
      const booklistpreview = async (ids) => {
       window.scrollTo(0, 0);
       try {
             
                     const dataaddpreview = await Audiopreview(ids);
                     if(dataaddpreview.success === 1){
                            console.log(dataaddpreview);
                            setisbookpreview(dataaddpreview.bookpreviewsall);
                     }
              } catch (error) { throw error;}    
       }
       const editModepreview =() =>{
              setisEditing(true);
       }
      
       const editModepreviewchildedit =() =>{
              setisEditing(false);
              setisbookpreview([]);
              booklistpreview(props.match.params.Addpreview);
       }
    
      const toggle = () => {
               setaddClass({addClass: !addClass});
            }
            
       let boxClass = ["redmoesss"];
       if(addClass) {
              boxClass.push('redmoesssqqq');
       
       }


       const externallinks = (url) => {
              if (!!url && !!url.trim()) { 
                     url = url.trim(); 
                     if (!/^(https?:)?\/\//i.test(url)) { 
                         url = 'http://' + url; 
                         window.open(url, '_blank');
                     }else{
                            window.open(url, '_blank');
                     }
                 } 
            }  

   

        return(
       <div className="bookadspreview">
              <div className="container">
                     <div className="row">
                     {isbookpreview.length !== 0 ? <>
                     {isbookpreview.map((item, index) => ( 
                             <div key={item.audio_id} className="col-md-12" style={{padding:0}}> 
                          
                     {isEditing === true  ?
                             <AudioBookedit audio_id={props.match.params.Addpreview} title={item.title} written={item.written} narrated={item.narrated} hrs={item.hrs} mins={item.mins}imagefront={item.imagefront} description={item.description} price={item.price} websitelink={item.websitelink} purchaselink={item.purchaselink}  editModepreviewchilds={editModepreviewchildedit } />

                             : 
                             <>
                            <div className="col-md-9 yyy">
                            {theUser === item.user_id || theUser === 2 || theUser === 6 ? <>  
                                   <button className="thm-btn pricing-one__btn editsss" onClick={editModepreview}> Edit <FontAwesomeIcon icon={['fas','edit']} className="iconsss2" /></button>
                                  
                             </>: null}
                          
                     <div className="clearfix"></div>

                    
                            <div className="col-md-12 zzz8 zzz8s" style={{padding:0}}>
                         
                            <div className="col-md-4 ">
                            <img src={BaseURL+item.imagefront}  alt="" />
                            <h1>{item.title}</h1>
                            </div>
                            <div className="col-md-8 " style={{padding:0}}>
                         
                            <div className="zzz7">
                       
                       <h3> <FontAwesomeIcon icon={['fas','feather-alt']} className="iconsss2" />  Written By  : <span> {item.written}</span></h3>
                        <hr />
                        <h3><FontAwesomeIcon icon={['fas','user-edit']} className="iconsss2" />  Narrated By : <span> {item.narrated}</span></h3>
                        <hr />
                        <h3> <FontAwesomeIcon icon={['fas','clock']} className="iconsss2" />   Length :     <span>
                        {item.hrs !== 0 ? 
                        <>
                        <span className="price2"> {item.hrs}  </span> hrs and 
                        </>
                        : null
                         }
                         {item.mins !== 0 ? 
                        <>
                         <span className="price2"> {item.mins} </span> mins
                        </>
                        : null
                         }
                        </span>
                        </h3>
                        <hr />
                        {item.price !== 0 ? <>
                        <h3> <FontAwesomeIcon icon={['fas','money-bill']} className="iconsss2" /> Price : <span style={{color:"#ff4242"}} > ${item.price} </span></h3>
                        <hr />
                        </>
                        : null}
                        <h3> <FontAwesomeIcon icon={['fas','link']} className="iconsss2" />  Book Purchase Link :  <p onClick={() => externallinks(item.purchaselink)}>{item.purchaselink} </p> </h3>
                        <hr />
                        <h3> <FontAwesomeIcon icon={['fas','globe-americas']} className="iconsss2" />  Website Link  : <p onClick={() => externallinks(item.websitelink)}>{item.websitelink} </p> </h3>

                        <hr />
                          <div className="ois__sharsss"> 
<h2> <FontAwesomeIcon icon={['fas','share']} className="iconsss2" />Share</h2>
       <FacebookShareButton 
              url={BaseURLShare +'AudioBookPreview/'+ props.match.params.Addpreview}
              quote={item.title}
              hashtag="#giobooks"
              className="ois__sharsss__facebook"
       > <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 24 24" fill="#1878f3"><path d="M24 12.07C24 5.41 18.63 0 12 0S0 5.4 0 12.07C0 18.1 4.39 23.1 10.13 24v-8.44H7.08v-3.49h3.04V9.41c0-3.02 1.8-4.7 4.54-4.7 1.31 0 2.68.24 2.68.24v2.97h-1.5c-1.5 0-1.96.93-1.96 1.89v2.26h3.32l-.53 3.5h-2.8V24C19.62 23.1 24 18.1 24 12.07"/></svg> </FacebookShareButton>
       <TwitterShareButton
              url={BaseURLShare +'AudioBookPreview/'+ props.match.params.Addpreview}
              title={item.title}
              hashtag="#giobooks"
              className="ois__sharsss__twitter"
       > <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 24 24" fill="#1da1f3"><path d="M24 4.37a9.6 9.6 0 0 1-2.83.8 5.04 5.04 0 0 0 2.17-2.8c-.95.58-2 1-3.13 1.22A4.86 4.86 0 0 0 16.61 2a4.99 4.99 0 0 0-4.79 6.2A13.87 13.87 0 0 1 1.67 2.92 5.12 5.12 0 0 0 3.2 9.67a4.82 4.82 0 0 1-2.23-.64v.07c0 2.44 1.7 4.48 3.95 4.95a4.84 4.84 0 0 1-2.22.08c.63 2.01 2.45 3.47 4.6 3.51A9.72 9.72 0 0 1 0 19.74 13.68 13.68 0 0 0 7.55 22c9.06 0 14-7.7 14-14.37v-.65c.96-.71 1.79-1.6 2.45-2.61z"/></svg> </TwitterShareButton> 
       <LinkedinShareButton
              url={BaseURLShare +'AudioBookPreview/'+ props.match.params.Addpreview}
              title={item.title}
              className="ois__sharsss__linkedin"
       > <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 24 24" fill="#0966c3"><path d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zM8.951 9.404H6.165V17.5H8.95V9.404zm6.841-.192c-1.324 0-1.993.629-2.385 1.156l-.127.181V9.403h-2.786l.01.484c.006.636.007 1.748.005 2.93l-.015 4.683h2.786v-4.522c0-.242.018-.484.092-.657.202-.483.66-.984 1.43-.984.955 0 1.367.666 1.408 1.662l.003.168V17.5H19v-4.643c0-2.487-1.375-3.645-3.208-3.645zM7.576 5.5C6.623 5.5 6 6.105 6 6.899c0 .73.536 1.325 1.378 1.392l.18.006c.971 0 1.577-.621 1.577-1.398C9.116 6.105 8.53 5.5 7.576 5.5z"/></svg></LinkedinShareButton>
        <PinterestShareButton
              url={BaseURLShare +'AudioBookPreview/'+ props.match.params.Addpreview}
              media={BaseURLuploads + item.imagefront}
              className="ois__sharsss__pinterest"
       > <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 24 24" fill="#d40928"><path d="M12 0a12 12 0 0 0-4.82 23c-.03-.85 0-1.85.21-2.76l1.55-6.54s-.39-.77-.39-1.9c0-1.78 1.03-3.1 2.32-3.1 1.09 0 1.62.81 1.62 1.8 0 1.09-.7 2.73-1.06 4.25-.3 1.27.63 2.31 1.89 2.31 2.27 0 3.8-2.92 3.8-6.38 0-2.63-1.77-4.6-4.99-4.6a5.68 5.68 0 0 0-5.9 5.75c0 1.05.3 1.78.78 2.35.23.27.26.37.18.67l-.25.97c-.08.3-.32.4-.6.3-1.67-.69-2.46-2.52-2.46-4.59 0-3.4 2.88-7.5 8.58-7.5 4.58 0 7.6 3.32 7.6 6.88 0 4.7-2.62 8.22-6.48 8.22-1.3 0-2.51-.7-2.93-1.5l-.84 3.3c-.26.93-.76 1.86-1.21 2.58A11.99 11.99 0 0 0 24 12 12 12 0 0 0 12 0z"/></svg></PinterestShareButton>
       <WhatsappShareButton
              url={BaseURLShare +'AudioBookPreview/'+ props.match.params.Addpreview}
              title={item.title}
              separator=":: "
              className="ois__sharsss__whatsup"
       > <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 24 24" fill="#00e676"><path d="M24 11.7c0 6.45-5.27 11.68-11.78 11.68-2.07 0-4-.53-5.7-1.45L0 24l2.13-6.27a11.57 11.57 0 0 1-1.7-6.04C.44 5.23 5.72 0 12.23 0 18.72 0 24 5.23 24 11.7M12.22 1.85c-5.46 0-9.9 4.41-9.9 9.83 0 2.15.7 4.14 1.88 5.76L2.96 21.1l3.8-1.2a9.9 9.9 0 0 0 5.46 1.62c5.46 0 9.9-4.4 9.9-9.83a9.88 9.88 0 0 0-9.9-9.83m5.95 12.52c-.08-.12-.27-.19-.56-.33-.28-.14-1.7-.84-1.97-.93-.26-.1-.46-.15-.65.14-.2.29-.75.93-.91 1.12-.17.2-.34.22-.63.08-.29-.15-1.22-.45-2.32-1.43a8.64 8.64 0 0 1-1.6-1.98c-.18-.29-.03-.44.12-.58.13-.13.29-.34.43-.5.15-.17.2-.3.29-.48.1-.2.05-.36-.02-.5-.08-.15-.65-1.56-.9-2.13-.24-.58-.48-.48-.64-.48-.17 0-.37-.03-.56-.03-.2 0-.5.08-.77.36-.26.29-1 .98-1 2.4 0 1.4 1.03 2.76 1.17 2.96.14.19 2 3.17 4.93 4.32 2.94 1.15 2.94.77 3.47.72.53-.05 1.7-.7 1.95-1.36.24-.67.24-1.25.17-1.37"/></svg></WhatsappShareButton>
      
</div>

                        </div>
                       
                                   </div>
                                  
                         
                          </div>
                          <div className="col-md-12" style={{backgroundColor: '#ffffffb3'}}>
                      

                          <div  className={boxClass.join(' ')}>
		                  {/* {descriptionviews} */}
                          <div dangerouslySetInnerHTML={{ __html: item.description }} />
                            </div>

                            {addClass ? 
                             <p className="redmoesssmmm" onClick={toggle}>  </p>
                             :
                             <p className="redmoesssmmm" onClick={toggle}> More </p>
                             }

</div>





                          </div>
              <div className="col-md-3 bookadspreviewlsss uuu">
                     {item.profile_image !== '' ?
                            <>
                                   <img src={BaseURL+item.profile_image} alt="userprofile" className="profileimgsssbooks" />
                            </>
                     : 
                     
                            <>
                                   <img src={userphotos} alt="userprofile" className="profileimgsssbooks"/>
                            </>
                            }
                 <div className="clearfix"></div>
                 <h2>{item.name}</h2>
                    

                 {isimpresivelatest.length > 0 ? <>
                   
                   {isimpresivelatest.map((item, index) => ( 
                            <div key={item.books_id} className="col-md-12 lll">
                            <div className="aaa4">
              <Link key={item.books_id} to={`/Bookpreview/${item.books_id}`}>
            
                 <section className="app" id="app" data-current-media="book">
                 <div className="heading  price align-center color-white subcolor-main icon-type-hidden ltx-shop-slider-price heading-tag-h2" id="like_sc_header_892432379"><h2 className="header ">$27.36</h2></div>
                   
                             <div className="book-wrapper2">
                                   <img src={BaseURLuploads + item.imagefront} alt="books" />
                             </div>
                             
                            <h4> {item.title} </h4>
                             <div className="desc23sss" >
                                 <div dangerouslySetInnerHTML={{ __html: item.description }} />
                          </div>  
                           </section>    
     
             
             <div className="arrow-read-more">
                                 <FontAwesomeIcon icon={['fas','arrow-right']} className="iconsss2" />
                                       
                                        </div>
             <div className="arrow-read-more-2 secondary-font">Read More</div>
                                 </Link>
              </div> 
              </div> 
                          )) }
                        
                          </>
                          : <ContentLoader height={300} width={500} speed={2} primarycolor="#f3f3f3" secondarycolor="#ecebeb" > 
                         <rect x="37" y="27" rx="0" ry="0" width="227" height="300"/> 
                        
                           </ContentLoader>  }





                    
                            </div>
                             
                            
                     
                     </>
                     }



                      </div>
                      ))}
                      </>
                      : 
                      <>
                       <div className="col-md-8 yyy">
                      <ContentLoader height={1700} width={1000} speed={3} primarycolor="#f3f3f3"  secondarycolor="#ecebeb" > 
                    
                     <rect x="0" y="07" rx="0" ry="0" width="477" height="27"/> 
                     <rect x="0" y="67" rx="0" ry="0" width="300" height="377"/> 
                     <rect x="347" y="97" rx="0" ry="0" width="427" height="27"/> 
                     <rect x="397" y="167" rx="0" ry="0" width="377" height="27"/> 
                     <rect x="397" y="217" rx="0" ry="0" width="377" height="27"/> 
                     <rect x="397" y="277" rx="0" ry="0" width="377" height="27"/> 
                     <rect x="397" y="327" rx="0" ry="0" width="377" height="27"/> 
                      
                      </ContentLoader>
             
                      </div> 
                      <div className="col-md-4 ">
                      <ContentLoader height={1700} width={1000} speed={3} primarycolor="#f3f3f3"  secondarycolor="#ecebeb" > 
                     <rect x="30" y="0" rx="0" ry="0" width="276" height="276"/> 

                     <rect x="30" y="317" rx="0" ry="0" width="277" height="27"/> 
                      
                      </ContentLoader>
                      </div> 
                   </>
              }
             
              
             </div>
             </div>
             </div>
        );
    }

export default AudioBookPreview;
