// this component for about page
import React, { Component } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import Loader from 'react-loader-spinner'
import Axios from 'axios';

import {toast} from "react-toastify";

const TEST_SITE_KEY = "6Lczw_wUAAAAAKs_-HJQMbK_VxUKnJ0323uUMQW5";
const DELAY = 1500;

const BaseURL= 'https://giobooks.com/';

class Registerpage extends Component{
  
   constructor(props, ...args) {
    super(props, ...args);
    this.state = {
      callback: "not fired",
      value: "[empty]",
      load: false,
      expired: "false",
      recaptchaLoaded: false,
      isLoading: false,
      
     
    };
    this._reCaptchaRef = React.createRef();
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({ load: true });
    }, DELAY);
    console.log("didMount - reCaptcha Ref-", this._reCaptchaRef);
  }
  handleChange = value => {
    this.setState({ value });
   
  };
  asyncScriptOnLoad = () => {
    this.setState({ recaptchaLoaded: true });
    this._reCaptchaRef.current.execute();
  };
  
Register = (event) => {
  let user_name = this.username.value;
  let user_email = this.useremail.value;
  let password = this.password.value;
  let conformpassword = this.conformpassword.value;
  let membership = this.membership.value;
  let recaptcha_response = this.recaptcha_response.value;

    this.setState({ isLoading: true });

    event.preventDefault();
    event.persist();
    if (password !== conformpassword) {
        this.setState({ isLoading: false });
            toast.error('Password and Confirm Password Dismatched');
      }else { 
    Axios.post(BaseURL+'login/Register',{
        user_name:user_name,
        user_email:user_email,
        password:password,
        membership:membership,
        recaptcha_response:recaptcha_response,
    })
    .then(function ({data}) {
        if(data.success === 1){
            console.log(data);
            this.setState({ isLoading: false });
            toast('Create an account successfully');
            
            event.target.reset();
        }
        else{
            this.setState({ isLoading: false });
            toast.error(data.msg, {
                position: "top-center",autoClose: 7000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
              });
        }
    }.bind(this))
    .catch(function (error) {
        this.setState({ isLoading: false });
    });
}
}
    

    render(){
     
        const { value, load, recaptchaLoaded,isLoading } = this.state || {};
        return(
            <form onSubmit={this.Register} className="login-forms">
              <div className="breadcrumbs">
                            <div className="content-box">
                                <h1>  Create a new account to post your book </h1>
                            </div>
                      </div>
                <div className="clearfix"></div>
            <div className="form-row">
            
                <div className="form-group col-md-12">
                    <label className="font-weight-bold">Full Name</label>
                    <input type="text" name="username" ref={(val) => this.username = val} className="form-control" placeholder="Your name here..." required   />
                   
                </div>
                <div className="form-group col-md-12">
                    <label className="font-weight-bold">Email Address</label>
                    <input type="email" name="useremail" ref={(val) => this.useremail = val} className="form-control" placeholder="Your email here..." required />
                </div>
                <div className="form-group col-md-12">
                    <label className="font-weight-bold"> Password </label>
                    <input type="password" name="password" ref={(val) => this.password = val} className="form-control" placeholder="Your password here..."  required />
                    
                </div>
                <div className="form-group col-md-12">
                    <label className="font-weight-bold"> Confirm Password </label>
                    <input type="password" name="conformpassword" ref={(val) => this.conformpassword = val} className="form-control" placeholder="Your password here..." required />
                </div>
                

                <input type="hidden" name="recaptcha_response" ref={(val) => this.recaptcha_response = val} className="form-control" placeholder="Your password here..." required  defaultValue={value} />

                {load && (
          <ReCAPTCHA
            style={{ display: "inline-block" }}
            theme="dark"
            size="invisible"
            ref={this._reCaptchaRef}
            sitekey={TEST_SITE_KEY}
            onChange={this.handleChange}
            asyncScriptOnLoad={this.asyncScriptOnLoad}
          />
        )}


                <div className="form-group col-md-12 ">
                {isLoading ?
                            <>
                                   <Loader type="ThreeDots" color="#f64442" height={67} width={67} style={{float: "left"}}/>
                            </>
                         : 
                    <button type="submit" className="thm-btn pricing-one__btn" style={{
                      marginBottom: "37px"}} disabled={!recaptchaLoaded}> Register </button>
                }
                </div>
            </div>
           
        </form>  
        );
    }
}
export default Registerpage;