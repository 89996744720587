
import React, { Component } from 'react';

import ReCAPTCHA from "react-google-recaptcha";
import Loader from 'react-loader-spinner';
import { ToastContainer,toast } from "react-toastify";
import Axios from 'axios';

import {Link } from "react-router-dom"; 


let BaseURL = 'https://giobooks.com/';


const TEST_SITE_KEY = "6LcQpbYZAAAAAA0gDuyJbNttVUJX3U9TWzqEo4zG";
const DELAY = 1500;

class Contmail extends Component{
    constructor(props, ...args) {
        super(props, ...args);
        this.state = {
          callback: "not fired",
          value: "[empty]",
          load: false,
          expired: "false",
          recaptchaLoaded: false,
          isLoading: false
        };
        this._reCaptchaRef = React.createRef();
      }
      
  componentDidMount() {
    setTimeout(() => {
      this.setState({ load: true });
    }, DELAY);
    console.log("didMount - reCaptcha Ref-", this._reCaptchaRef);
    window.scrollTo(0, 0);
  }

  handleChange = value => {
    //console.log("Captcha value:", value);
    this.setState({ value });
   
  };

  asyncScriptOnLoad = () => {
    this.setState({ recaptchaLoaded: true });
    //console.log("scriptLoad - reCaptcha Ref-", this._reCaptchaRef);
    
    this._reCaptchaRef.current.execute();
  };


       contactMessagesss = (event) => {
              this.setState({ isLoading: true });
          event.preventDefault();event.persist();
          return Axios.post(BaseURL+'login/contact2',{
              name:this.name.value,
              email:this.email.value,
              message:this.message.value,
              recaptcha_response:this.recaptcha_response.value,
          })
          .then(function ({data}) {
                 console.log(data);
              if(data.success === 1){
                     toast('Mail Send Successfully');
                     this.setState({ isLoading: false });
                     event.target.reset();
                 }
                 else{
                     this.setState({ isLoading: false });
                     toast.error(data.msg, {
                         position: "top-center",autoClose: 7000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
                       });
                     }
          }.bind(this))
          .catch(function (error) {
            console.log(error);
            this.setState({ isLoading: false });
        });              
                }



    render(){
        const { value, load, recaptchaLoaded } = this.state || {};
        return(
          <>
           


        <ToastContainer />

        <div className="login-1">
                  <div className="container">
                     <div className="row">
                     
                       <div className="col-md-2">
                              &nbsp;

                       </div>
                       <div className="col-md-8 logsss3">
                          
                
              <div className="central-meta3">
            
           <form onSubmit={this.contactMessagesss} className="central-meta login-forms">
           <h1>  Contact Form  </h1>
           <div className="clearfix"></div>
                <div className="form-row">
                <div className="form-group col-md-12">
                        <label className="font-weight-bold"> Name <span className="adverts-form-required"> * </span></label>
                        <input type="text" name="name" ref={(val) => this.name = val} className="form-control" placeholder=" Enter Your Name " required/>
                    </div>
                    <div className="form-group col-md-12">
                        <label className="font-weight-bold"> Email Address <span className="adverts-form-required"> * </span></label>
                        <input type="email" name="email" ref={(val) => this.email = val} className="form-control" placeholder="Email Address" required/>
                    </div>
                    <div className="form-group col-md-12">
                          <label className="font-weight-bold"> Message <span className="adverts-form-required"> * </span> </label>
                          <textarea className="form-control" id="message" name="message" rows="10" ref={(val) => this.message = val} required  />
                      </div>
                      <input type="hidden" name="recaptcha_response" ref={(val) => this.recaptcha_response = val} className="form-control" placeholder="Your password here..." required  defaultValue={value} />

                        {load && (
                        <ReCAPTCHA
                        style={{ display: "inline-block" }}
                        theme="dark"
                        size="invisible"
                        ref={this._reCaptchaRef}
                        sitekey={TEST_SITE_KEY}
                        onChange={this.handleChange}
                        asyncScriptOnLoad={this.asyncScriptOnLoad}
                        />
                        )}
                    <div className="form-group col-md-12 text-right">
                    {this.state.isLoading  ? 
                            <>
                                   <Loader type="ThreeDots" color="#00BFFF" height={77} width={77} style={{float: "right"}}/>
                            </>
                         : 
                        <button type="submit" className="thm-btn pricing-one__btn" disabled={!recaptchaLoaded}> Submit </button>
                      }
                    </div>
                </div>
            </form>  
            </div>
            </div>
            </div>
            </div>
            </div>
            </>
        );
    }
}
export default Contmail;