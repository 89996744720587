
import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';

import Loader from 'react-loader-spinner';
import {Link } from "react-router-dom"; 

import { useHistory } from "react-router-dom";

let BaseURL = 'https://giobooks.com/';


function Cancel(props) {

  let history = useHistory();

  const {paypalsuccessbook} = useContext(MyContext);
  const [paypaldetails, setpaypaldetails] = useState([]);
  
  useEffect( () => {
     window.scrollTo(0, 0);
     successdata(props.match.params.Addpreview);
 }, []);


 const successdata = async (paymentid) => {
  const data = await paypalsuccessbook(paymentid);
  if(data.success === 1){
       setpaypaldetails(data.bookslist);
  }
}


    return (
      <>
       <div className="successs">
        <div className="container">
          <div className="row">
            <div className="col-md-12" style={{padding:0}}>
              <div className="breadcrumbs">
                <div className="content-box">
                  <h1> PayPal Transaction </h1>
                </div>
              </div>
            </div>
      
         
        <div className="col-md-2">
                        &nbsp;
              </div>
              <div className="col-md-7 ooo2">
              <h2>Your PayPal Transaction Canceled.</h2>
          
           
                          <Link to={`/Myaccount`} className="thm-btn pricing-one__btn" > Payment Again </Link>
                      
                                
                   
                   
              </div>

       </div>
  </div>
</div>
      </>
    );
  }


export default Cancel;