import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ContentLoader from "react-content-loader";
import Loader from 'react-loader-spinner';
import { useHistory } from "react-router-dom";

function Bookadd() {

       let history = useHistory();
       
    const {pricelistall,Addplan,rootState} = useContext(MyContext);
    const [isprice, setisprice] = useState({Standard:'',Featured:'',Impressive:'',BestDeals:''});
    const {theUser} = rootState;
    const [packagePlan, setpackagePlan] = useState('Featured');
    
    const [loadingadd, setloadingadd] = useState(false);

    useEffect( () => {
          
        pricelist();
    window.scrollTo(0, 0);
       }, []); 

       const pricelist = async () => {
              const data = await pricelistall();
              console.log('wwwwwww');
              if(data.success === 1){
                     console.log(data);
                     setisprice({ 
                            ...isprice,
                            Standard:data.priceall[3].price,
                            Featured:data.priceall[2].price,
                            Impressive:data.priceall[1].price,
                            BestDeals:data.priceall[0].price,
                     }) ;
              }
       }
       const booksAddplans = async (event) => {
              try {
                     setloadingadd(true);
                     event.preventDefault();
                     event.persist();
                      if(theUser !== null){
                            const dataplan = await Addplan(theUser,packagePlan);
                            if(dataplan.success === 1){
                                   console.log(dataplan);
                                   event.target.reset(); 
                                   setloadingadd(false);
                                   history.push(`/3b2ff1b2e60e958349f58d860c1babd6/${dataplan.newids}`);
                                   
                            }
                            setloadingadd(false);
              }
              } catch (error) { throw error;}    
       }


const packageselected = (event) => {
       setpackagePlan(event.target.value)
     }


        return(
               <div className="addbook">
                     <div className="container">
                            <div className="row">
                                   <div className="col-md-12">
        <h3 style={{marginBottom:"7px"}}> <span className="cols">Onetime Fee: </span> Paypal / Stripe / Credit Card Payment </h3>
        <p className="renewtext"> <span style={{color: "#ff4747"}}> Note: </span> This is a one-time payment. There's NO automatic renewal when your membership ends, the payment has to be made again manually. </p>
                                   </div>

   {isprice.Standard !== '' ? 
       <form onSubmit={booksAddplans}>



              <div className="col-md-2" style={{padding:0}}>
                     &nbsp;
              </div>
              <div className="col-md-7">
              
     
       <ul className="timeline">
       {loadingadd ?
                            <><div className="iii2"> 
                                   <Loader type="ThreeDots" color="#f64442" height={67} width={67} style={{float: "left"}}/>
                                   </div>
                            </>
                         : 
       <button type="submit" className="thm-btn lsss"> Continue <FontAwesomeIcon icon={['fas','arrow-circle-right']} /></button>
}
              <li>
                     <div className="direction-lsss"> 
                            <div className="radioqqq">
                                   <input type="radio" className="radio" id="awesome-item-1"
                                   value="Standard"
                                   name="Standard"
                                   checked={packagePlan === "Standard"}
                                   onChange={packageselected}  />
                                   <label className="radio__label" htmlFor="awesome-item-1"> Active </label>
                            
                                   <div className="clearfix"></div>
                            </div>
                     </div>
                     <div className="direction-r">
                     <div className="flag-wrapper">
                            <span className="flag"> Standard - <div className="price">
                                   <span className="currency">$</span>
                                   <span className="value">{isprice.Standard}</span>
                            </div></span>
                     </div>
                     <div className="radioqqq">
                            <div className="clearfix"></div>
                            <span className="listing-duration">1.5 years listing with search optimization  </span> <br/>
                                          
                     </div>
                     </div>
              </li>
 <li>
                     <div className="direction-lsss"> 
                            <div className="radioqqq">
                                   <input type="radio" className="radio" id="awesome-item-2"
                                   value="Featured"
                                   name="Featured"
                                   checked={packagePlan === "Featured"}
                                   onChange={packageselected}  />
                                   <label className="radio__label" htmlFor="awesome-item-2"> Active </label>
                            
                                   <div className="clearfix"></div>
                            </div>
                     </div>
                     <div className="direction-r">
                     <div className="flag-wrapper">
                            <span className="flag"> Featured - <div className="price">
                                   <span className="currency">$</span>
                                   <span className="value">{isprice.Featured} </span>
                            </div></span>
                     </div>
                     <div className="radioqqq">
                            <div className="clearfix"></div>
                            <span className="listing-duration"> Three years listing with search optimization </span>
                                          
                     </div>
                     </div>
              </li>
<li>
                     <div className="direction-lsss"> 
                            <div className="radioqqq">
                                   <input type="radio" className="radio" id="awesome-item-3"
                                   value="BestDeals"
                                   name="BestDeals"
                                   checked={packagePlan === "BestDeals"}
                                   onChange={packageselected}  />
                                   <label className="radio__label" htmlFor="awesome-item-3"> Active </label>
                            
                                   <div className="clearfix"></div>
                            </div>
                     </div>
                     <div className="direction-r">
                     <div className="flag-wrapper">
                            <span className="flag"> Best Deals - <div className="price">
                                   <span className="currency">$</span>
                                   <span className="value">{isprice.BestDeals} </span>
                            </div></span>
                     </div>
                     <div className="radioqqq">
                            <div className="clearfix"></div>
                            <span className="listing-duration"> Ten years listing, search optimization, Email marketing to over 140000 readers</span>
                                          
                     </div>
                     </div>
              </li>
<li>
                     <div className="direction-lsss"> 
                            <div className="radioqqq">
                                   <input type="radio" className="radio" id="awesome-item-4"
                                   value="Impressive"
                                   name="Impressive"
                                   checked={packagePlan === "Impressive"}
                                   onChange={packageselected}  />
                                   <label className="radio__label" htmlFor="awesome-item-4"> Active </label>
                            
                                   <div className="clearfix"></div>
                            </div>
                     </div>
                     <div className="direction-r">
                     <div className="flag-wrapper">
                            <span className="flag"> Impressive - <div className="price">
                                   <span className="currency">$</span>
                                   <span className="value">{isprice.Impressive} </span>
                            </div></span>
                     </div>
                     <div className="radioqqq">
                            <div className="clearfix"></div>
                            <span className="listing-duration">Permanent listing, search optimization,  Email marketing to over 140000 readers, Recommended to over 300000 LinkedIn members. Posting on Facebook page with 150000 followers.</span>
                                          
                     </div>
                     </div>
              </li>
<li className="disllqqqsss">
                     <div className="direction-lsss"> 
                            <div className="radioqqq">
                                   <input type="radio" className="radio" id="awesome-item-5"
                                   value="Free"
                                   name="Free"
                                   checked={packagePlan === "Free"}
                                   onChange={packageselected}  />
                                   <label className="radio__label" htmlFor="awesome-item-5"> Active </label>
                            
                                   <div className="clearfix"></div>
                            </div>
                     </div>
                     <div className="direction-r">
                     <div className="flag-wrapper">
                            <span className="flag"> Free </span>
                     </div>
                     <div className="radioqqq">
                            <div className="clearfix"></div>
                                          
                     </div>
                     </div>
              </li> 
              {loadingadd ?
                            <>
                                  <div className="iii3"> 
                                   <Loader type="ThreeDots" color="#f64442" height={67} width={67} style={{float: "left"}}/>
                                   </div>
                            </>
                         : 
              <button type="submit" className="thm-btn rsss"> Continue <FontAwesomeIcon icon={['fas','arrow-circle-right']} /></button>
}
              </ul>
              </div>
              </form>
             : 
             <>
              <div className="col-md-7">
              <ContentLoader height={1700} width={1000} speed={3} primarycolor="#f3f3f3"  secondarycolor="#ecebeb" > 
             <rect x="127" y="0" rx="0" ry="0" width="176" height="57"/> 
             <rect x="207" y="77" rx="0" ry="0" width="7" height="1000"/>

             <rect x="200" y="147" rx="100" ry="100" width="20" height="20"/> 
             <rect x="60" y="137" rx="0" ry="0" width="122" height="50"/> 
             <rect x="247" y="137" rx="0" ry="0" width="220" height="55"/> 
             <rect x="247" y="207" rx="0" ry="0" width="477" height="17"/> 
              
             <rect x="200" y="327" rx="100" ry="100" width="20" height="20"/> 
             <rect x="60" y="317" rx="0" ry="0" width="122" height="50"/> 
             <rect x="247" y="317" rx="0" ry="0" width="220" height="55"/> 
             <rect x="247" y="390" rx="0" ry="0" width="477" height="17"/> 

             <rect x="200" y="517" rx="100" ry="100" width="20" height="20"/> 
             <rect x="60" y="507" rx="0" ry="0" width="122" height="50"/> 
             <rect x="247" y="507" rx="0" ry="0" width="220" height="55"/> 
             <rect x="247" y="580" rx="0" ry="0" width="537" height="17"/> 
             <rect x="247" y="607" rx="0" ry="0" width="137" height="17"/> 

             <rect x="200" y="717" rx="100" ry="100" width="20" height="20"/> 
             <rect x="60" y="707" rx="0" ry="0" width="122" height="50"/> 
             <rect x="247" y="707" rx="0" ry="0" width="220" height="55"/> 
             <rect x="247" y="777" rx="0" ry="0" width="537" height="17"/> 
             <rect x="247" y="807" rx="0" ry="0" width="537" height="17"/> 
             <rect x="247" y="837" rx="0" ry="0" width="137" height="17"/> 
            
             <rect x="200" y="937" rx="100" ry="100" width="20" height="20"/> 
             <rect x="60" y="927" rx="0" ry="0" width="122" height="50"/> 
             <rect x="247" y="927" rx="0" ry="0" width="220" height="55"/> 

             <rect x="127" y="1100" rx="0" ry="0" width="176" height="57"/> 
              </ContentLoader>
     
              </div>
               </>
       }

               </div>
       </div>
</div>
        );
    }

export default Bookadd;