
import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';
import Loader from 'react-loader-spinner';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Link } from "react-router-dom"; 

import ContentLoader from "react-content-loader";
let BaseURL = 'https://giobooks.com/uploads/';

function Pendingvideo(props) {

       const {paypendingvideo,rootState} = useContext(MyContext);
       const [ispendingpayvideo, setispendingpayvideo] = useState({books:[]});    
       const {theUser} = rootState;
    


       useEffect( () => {
              pendingvideo(props.match.params.bookviews);
            }, []);


            const pendingvideo = async(storageid) =>{
              try {
                     const booksallvideo = await paypendingvideo(storageid);
                     if(booksallvideo.success === 1){
                            console.log(booksallvideo);
                            setispendingpayvideo({
                                   ...ispendingpayvideo,
                                   books:booksallvideo.bookslist
                            }); 
                     }
              } catch (error) { throw error;}      
       }
       const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]{11,11}).*/;
            return ( 
                   <>
                   <div className="paymentpendings">
                   <div className="container">
                   <div className="row">
                   <div className="col-md-12" style={{padding:0}}>
              <div className="breadcrumbs">
                <div className="content-box">
                  <h1> Book Payment Pending </h1>
                </div>
              </div>
            </div>

            


       {ispendingpayvideo.books.length > 0 ?<>
                 {ispendingpayvideo.books.map((item, index) => ( 
                        
                     <div key={item.video_id} className="col-md-6" >
                            {theUser === item.user_id? <> 
                                  <div className="basccsss2">
                                      
                                          <div className="col-md-12 ">
                                          <Link to={`/f93718ba896b0ec0ec82e0decfa8ece7/${item.video_id}`}  className="btn btn-primary5">  continue payment processing... </Link>      
                            <div className=" yyy">

       {item.url.match(regExp) ? <> 
            {item.url.match(regExp).length >= 2 ? <>      
                            <iframe id="ytplayer" type="text/html" className="videosss"
                         src={`https://www.youtube.com/embed/`+item.url.match(regExp)[2]+`?rel=0&showinfo=0&color=white&iv_load_policy=3`}
                         frameBorder="0" allowFullScreen></iframe> 

                          </>:null}
                          </>:null}
                          
                          </div>
                          <h4>{item.title}</h4>
                                         
                                          
                                          </div>
                                          </div>


                            </>
                            : null}
                        
                        </div>

                 ))}
             
     






       </>
       : null}       


       
</div>
</div>
</div>
             </>
              );
          }
   
export default Pendingvideo;
