import React from "react";
import { Helmet } from "react-helmet";

const metaDecorator = require("./metaDecorator");

const HelmetMetaData = (props) => {
  let bookoistitle = props.title !== undefined ? props.title : "gioBooks | Book promotion, book trailers, and audiobooks marketing";
  let bookoisdescription = props.description !== undefined ? props.description :"One of the best sites for authors to promote their books globally. Quick sales and best results compared to other book marketing websites.";
  return (
    <Helmet>
      <title>{bookoistitle}</title>
      <meta property="og:title" content={bookoistitle} />
      <meta name="description" content={bookoisdescription} />
      <meta property="og:description" content={bookoisdescription} />
      <meta property="og:image" content={metaDecorator.hostname + props.imageUrl} />
      <meta
        property="og:url"
        content={metaDecorator.hostname + window.location.pathname + window.location.search}
      />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:image:alt" content={props.imageAlt} />
      <meta property="og:site_name" content="giobooks" />
     <meta property="og:locale" content="en_US" />
     <meta property="og:type" content="website" />
    </Helmet>
  );
};

export default HelmetMetaData;
