import React from 'react';
// Importing the Context Provider & Home Component
import MyContextProvider from './contexts/MyContext';
import Dashboard from './components/Dashboard'

function App() {
  return (
    <MyContextProvider>
        <Dashboard/>
    </MyContextProvider>
  );
}

export default App;