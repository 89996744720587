import React, { Component } from 'react'

import { Link } from "react-router-dom"; 


import search from '../Img/search.png';

let BaseURL = 'https://giobooks.com/';
let BaseURLUploads = 'https://giobooks.com/uploads/';

class Search extends Component {
  constructor() {
    super();
    this.state = {
     
      books:[],
      audio:[],
      trailers:[],
      publishers:[],
      isBooks:false,
      isAudioBooks:false,
      searchValue: '',
    };

  }

  componentWillMount(){
   
  }


  makeApiCall = searchInput => {
    //var searchUrl = `https://www.themealdb.com/api/json/v1/1/search.php?s=${searchInput}`;
    var searchUrl = (BaseURL+`viewall/searchAll?searchfilters=${searchInput}`);
    fetch(searchUrl)
    .then(response => {
    return response.json();
    })
    .then(jsonData => {
    console.log(jsonData);
    this.setState({ books: jsonData.allads });
    });
    };
   
  makeApiCallAudio = searchInput => {
    var searchUrl =  (BaseURL+`viewall/searchaudio?searchfilters=${searchInput}`);
    fetch(searchUrl)
    .then(response => { return response.json();})
    .then(jsonData => { this.setState({ audio: jsonData.audio });  });
    };

  makeApiCallTrailers = searchInput => {
    var searchUrl =  (BaseURL+`viewall/searchtrailers?searchfilters=${searchInput}`);
    fetch(searchUrl)
    .then(response => { return response.json();})
    .then(jsonData => { this.setState({ trailers: jsonData.trailers });  });
    };
  makeApiCallpublishers = searchInput => {
    var searchUrl =  (BaseURL+`viewall/searchpublishers?searchfilters=${searchInput}`);
    fetch(searchUrl)
    .then(response => { return response.json();})
    .then(jsonData => { this.setState({ publishers: jsonData.publishers });  });
    };


  handleSearch = () => {
    if(this.state.isBooks === false && this.state.searchValue !== null ){ 
      this.makeApiCall(this.state.searchValue);
    }
    else if(this.state.isAudioBooks === true){ 
      this.makeApiCallAudio(this.state.searchValue);
    }
    else if(this.state.isBooksTrailers === true){ 
      this.makeApiCallTrailers(this.state.searchValue);
    }
    else if(this.state.isPublishers === true){ 
      this.makeApiCallpublishers(this.state.searchValue);
    }
    };

  handleOnChange = (ValueHolder ) => {
   
    this.setState({ searchValue: ValueHolder.target.value, viewlists: true });


    if(this.state.isBooks === false && this.state.searchValue !== null ){ 
      this.makeApiCall(this.state.searchValue);
    }
    else if(this.state.isAudioBooks === true){ 
      this.makeApiCallAudio(this.state.searchValue);
    }
    else if(this.state.isBooksTrailers === true){ 
      this.makeApiCallTrailers(this.state.searchValue);
    }
    else if(this.state.isPublishers === true){ 
      this.makeApiCallpublishers(this.state.searchValue);
    }


    // let updateList = this.state.books;
    // updateList = updateList.filter(book => {
    //   return book.toLowerCase().search(
    //     event.target.value.toLowerCase()
    //     ) !== -1;
    // });

    // this.setState({
    //   books: updateList
    // });


    };





    Books =()=>{
      this.setState({ 
       isBooks: false,
       isAudioBooks: false,
       isBooksTrailers: false,
       isPublishers: false,
      });
      this.makeApiCall(this.state.searchValue);
    }
    AudioBooks =()=>{
      this.setState({ 
       isAudioBooks: true,
       isBooksTrailers: false,
       isPublishers: false,
       isBooks: true,
      });
      this.makeApiCallAudio(this.state.searchValue);
    }
    BooksTrailers =()=>{
      this.setState({ 
       isBooksTrailers: true,
       isAudioBooks: false,
       isPublishers: false,
       isBooks: true,
      });
      this.makeApiCallTrailers(this.state.searchValue);
    }
    Publishers =()=>{
      this.setState({ 
        isBooks: true,
        isAudioBooks: false,
        isBooksTrailers: false,
        isPublishers: true,
      });
      this.makeApiCallpublishers(this.state.searchValue);
    }









  render() {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]{11,11}).*/;  
    return (
      <>
    
    <div className=" search ">
        
        <div className="container">
          <div className="row">
            
          
            <div className="col-md-12">
            <div className="searchbar">
               <input name="text"  type="text" placeholder=" Search books, trailers, publishers ..."  onChange={ValueHolder  => this.handleOnChange(ValueHolder )} value={this.state.searchValue}
                />
                  <button onClick={this.handleSearch} className="btnmmm"> <img src={search} alt="search" />  </button>

            </div>
            </div>


           

                {this.state.viewlists === true ?
                <>
                <div className="col-md-1">
                  &nbsp;
                  </div>
                <div className="col-md-11">
                 <div className="central-metasearch">

            <div className="col-md-3">
                       <ul className="nav-tabssss">
                          <li onClick={this.Books} className={ this.state.isBooks? "nav-item": "active" } > Books</li>
                          <li onClick={this.AudioBooks} className={ this.state.isAudioBooks? "active": "nav-item" }> Audio Books</li>
                          <li onClick={this.BooksTrailers} className={ this.state.isBooksTrailers? "active": "nav-item" }> Books Trailers </li>
                          <li onClick={this.Publishers} className={ this.state.isPublishers ? "active": "nav-item" }> Publishers </li>
                      </ul>
             </div>
 
                        <div className="col-md-9">
                            {this.state.isBooks !== true ?
                            <>
                              <div className="set-title">
                                    <h5> Books </h5>
                                  
                                    {this.state.books ? (
                                        <>
                                          {this.state.books.map((book, index) => (

                       
                         <div key= {book.books_id}>

                                       
                    <div className="col-md-6 " style={{ padding: 0 }} >

                      <div className="stansss" >
                      
                        <div className="col-md-6 " style={{ padding: 0 }} >
                        <section className="app" id="app" data-current-media="book">
                          <article className="media-container">
                          <Link to={`/Bookpreview/${book.books_id}`} className="" >
                            <div className="book-wrapper">
                              <div className="book">
                                <div className="book__front">
                                  <img src={BaseURLUploads + book.imagefront} alt="books" />
                                </div>
                                <div className="book__paper"></div>
                                <div className="book__back"></div>
                              </div>
                              <div className="book-shadow"></div>
                            </div>
                            
                            </Link>
                          </article>
                        </section>
                      
                        </div>
                        <div className="col-md-6 " style={{ padding: 0 }} >
                        <h4>{book.title}</h4>
                        {book.category === 'other' ?
                          <h6>{book.other}</h6>
                          :
                          <h6>{book.category}</h6>
                        }
                        
                        <div className="about-links">
                           <Link to={`/Bookpreview/${book.books_id}`} className="base-btn1"> View Book </Link>
		                  	</div>
                      
                      </div>
                    </div>
                  </div>
                 
                   
                

                                          </div>
                                          



                                          ))}
                                        </>
                                    ) : (
                                    <p>Try searching for a books</p>
                                    )}
                                                      
                                        
                             </div>
                            </>
                            : null }



                        {this.state.isAudioBooks === true ?
                            <div className="set-title">
                                <h5>   Audio Books </h5>
                                {this.state.audio ? (
                                        <>
                                          {this.state.audio.map((audiobook, index) => (
                                          <div key={audiobook.audio_id}>
                        
                              <div className="col-md-4 audiosssbbb" >
                              <Link to={`/AudioBookPreview/${audiobook.audio_id}`} className="" >
                                {audiobook.written !== '' ?
                                  <h6> By: {audiobook.written}</h6>
                                  :
                                  <h6> &nbsp; </h6>
                                }
                                <h4>{audiobook.title}</h4>
                                <div className="booksrec4">
                                  <img src={BaseURLUploads + audiobook.imagefront} alt="books" />
                                 
                                </div>
                                <span className="cccgggsss2"> 
                                    <b> Length : </b> 
                                    {audiobook.hrs == 0  &&  audiobook.mins == 0 ?
                                    <span> Not Yet Known </span> 
                                  : 
                                  <>
                                <span className="price2"> {audiobook.hrs} </span>  hrs and  <span className="price2"> {audiobook.mins} </span> mins
                                  
                                  
                                  </>
                                  }
                                  
                                  </span>   
                                </Link>
                              </div>
                         


                                          </div>
                                          ))}
                                        </>
                                    ) : (
                                    <p>Try searching for a books</p>
                                    )}
                             </div>
                        : null } 
                       
                        {this.state.isBooksTrailers === true ?
                            <div className="set-title">
                                <h5>  Books Trailers </h5>

                                       {this.state.trailers ? (
                                        <>
                                          {this.state.trailers.map((trailersbook, index) => (
                                          <div key={trailersbook.video_id}>
                                              <div className="col-md-6 " style={{ padding: 0 }} >
                                              {trailersbook.url.match(regExp) ? 
                                          <> 
                                                 {trailersbook.url.match(regExp).length >= 2 ? 

                                                 <iframe id="ytplayer" type="text/html" className="videosss2"
                                                 src={`https://www.youtube.com/embed/` + trailersbook.url.match(regExp)[2] + `?rel=0&showinfo=0&color=white&iv_load_policy=3`}
                                                 frameBorder="0" allowFullScreen></iframe>
                                                 : null }
                                          </>
                                                 : null 
                                          } 
                                               
                                              </div>
                                                  
                                          </div>
                                          ))}
                                        </>
                                    ) : (
                                    <p>Try searching for a books</p>
                                    )}                                  
                               
                             </div>
                        : null }


                        {this.state.isPublishers === true ?
                          <div className="set-title">
                          <h5> Publishers </h5>

                          {this.state.publishers ? (
                                        <>
                                          {this.state.publishers.map((publisherslist, index) => (
                                            <div key={publisherslist.publisher_id}>
                                            <div className="col-md-6 "  >
                                              <div className="description " >
                                                <Link to={`/PublisherPreview/${publisherslist.publisher_id}`} className="" >
                                                  <h6 className=""> {publisherslist.title} </h6>
                                                        <div className="clearfix"> </div>
                                                    <div className="col-md-4" style={{ padding: 0 }} >
                                                    
                                                      <div className="booksrec2">
                                                        <img src={BaseURLUploads + publisherslist.imagefront} alt="books" />
                                                      
                                                      </div>
                                                    
                                                    </div>
                                                    
                                                    <div className="col-md-8 " style={{ padding: 0 }} >
                                                      <div className="product-header" className="single-btn2">
                                                        
                                                        <div dangerouslySetInnerHTML={{ __html: publisherslist.description }} />
                                                      </div>
                                                    </div>
                                                    <div className="clearfix"></div>
                                                  </Link>
                                                <a href={"mailto:" + publisherslist.email} rel="noopener noreferrer" target="_blank"> {publisherslist.email} </a>
                                              </div>
                                            </div>
                                          </div>
                                          ))}
                                        </>
                                    ) : (
                                    <p>Try searching for a books</p>
                                    )}            
                                                                  
                         
                       </div>
                          
                        : null }
                     
                     

                        </div>
                        </div>
                        </div>
                        </>
                        : null}
















         
          </div>
        </div>
      </div>
      </>
    );
  }
}

export default Search
